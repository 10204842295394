<template>
  <div v-if="loaded" class="print-box">
    <div class="print-left" :style="{ height: `${this.$util.getViewHeight() - 162}px` }">
      <div id="print-content" class="print-content">
        <!-- title -->
        <div class="title-box">
          <h1 class="title-main">佛山市金宏旺金属材料有限公司</h1>
          <h2 class="title-sub">采购申请单</h2>
        </div>

        <!-- formData1 -->
        <div class="form-box">
          <div class="form-list" v-for="(itemSt, indexSt) in formData1" :key="indexSt">
            <div class="form-item" v-for="(itemNd, indexNd) in itemSt" :key="indexNd">
              <div class="form-key">{{ itemNd.key }}{{ itemNd.key && '：' }}</div>
              <div class="form-val" v-if="itemNd.val">
                <span
                  v-if="
                    itemNd.val === 'shen_gou_bill_date' || itemNd.val === 'shen_gou_jiao_huo_ri_qi'
                  "
                >
                  {{ detailData.formData[itemNd.val].slice(0, 10) || '&nbsp;' }}
                </span>
                <span v-else>
                  {{ detailData.formData[itemNd.val] || '&nbsp;' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- tableData -->
        <div class="table-box">
          <PrintTable :data="detailData.list" />
        </div>

        <!-- 确认 -->
        <div class="flex">
          <div class="flex-1 f-bold">采购方确认及电话：</div>
          <div class="flex-1 f-bold">供应方确认：</div>
        </div>
      </div>
    </div>
    <div class="print-right">
      <div class="print-do">
        <div>
          <el-button class="print-btn" v-print="printData" type="primary" size="small">
            打印
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrintTable from './print-table'
export default {
  components: {
    PrintTable
  },
  data() {
    return {
      loaded: false,
      detailData: {},
      printData: {
        id: 'print-content',
        popTitle: '&nbsp;'
        // preview: true, // 是否开启预览
        // previewTitle: '打印预览', // 预览标题
      },
      formData1: [
        [
          {
            key: '销售单号',
            val: 'bill_code'
          },
          {
            key: '采购申请单号',
            val: 'shen_gou_code'
          },
          {
            key: '采购申请日期',
            val: 'shen_gou_bill_date'
          }
        ],
        [
          {
            key: '交货日期',
            val: 'shen_gou_jiao_huo_ri_qi'
          },
          {
            key: '业务员',
            val: 'saler_name'
          },
          {
            key: '',
            val: ''
          }
        ]
      ]
    }
  },
  computed: {
    applyCode() {
      return this.$route.query.code
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    // 获取详情数据
    getDetailData() {
      this.loaded = false
      this.$api({
        method: 'get',
        url: '/admin/gcvip/shengou/detailShenGouCode',
        params: {
          code: this.applyCode
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = {}
            let list = []
            data.formData = this.$util.deepCopy(res.data.formData)
            res.data.data.forEach((itemSt) => {
              itemSt.forEach((itemNd) => {
                list.push(itemNd)
              })
            })
            data.list = this.$util.deepCopy(list)
            this.detailData = this.$util.deepCopy(data)
          }
        })
        .catch(() => {})
        .then(() => {
          this.loaded = true
        })
    }
  }
}
</script>

<style lang="less" scoped>
// print
.print-box {
  display: flex;
  background: #f5f7fa;
  .print-left {
    margin: 10px;
    padding: 20px;
    width: 712px;
    background: #fff;
    overflow-y: auto;
    .scroll-bar();
  }
  .print-right {
    margin: 10px 10px 10px 0;
    padding: 10px;
    width: 200px;
    background: #fff;
    .print-btn {
      width: 100%;
    }
    .print-do {
      margin-bottom: 20px;
      padding: 20px;
      text-align: center;
      font-size: 14px;
      background: #f8f8f8;
    }
  }
}

// content
.print-content {
  font-size: 12px;
  line-height: 1.5;
  font-family: 'Arial,Microsoft YaHei,SimHei,SimSun';
  overflow: hidden;
}

// title
.title-box {
  margin: 10px 0 30px 0;
  text-align: center;
}
.title-main {
  margin: 0;
  font-size: 20px;
}
.title-sub {
  position: relative;
  margin: 10px 0 0 0;
  font-size: 20px;
}

// form
.form-box {
  position: relative;
  display: flex;
  margin-top: 20px;
}
.form-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  & ~ .form-list {
    margin-left: 10px;
  }
  .form-item {
    flex: 1;
    display: flex;
    align-items: center;
    .form-key {
      border-bottom: 1px solid #fff;
    }
    .form-val {
      flex: 1;
      padding: 2px 0;
      border-bottom: 1px solid #000;
    }
  }
}

// table
.table-box {
  margin: 20px 0;
}
</style>
