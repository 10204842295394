<template>
  <div class="box-dialog">
    <el-dialog
      width="420px"
      title="与客户同步关联"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" :rules="rules" :model="selfData" label-width="120px">
        <el-form-item label="加工商名称" prop="company_name">
          <el-input v-model="selfData.company_name" size="mini" readonly />
        </el-form-item>
        <el-form-item label="关联客户:" prop="customer_name">
          <el-select
            size="mini"
            placeholder="可搜索查询"
            default-first-option
            remote
            filterable
            clearable
            :remote-method="getCustomerList"
            :style="{ width: '100%' }"
            v-model="selfData.customer_name"
            @change="
              (selfData.customer_name = $event.customer_name),
                (selfData.customer_uuid = $event.company_uuid)
            "
          >
            <el-option
              v-for="(item, index) in customerListData"
              :key="index"
              :label="item.customer_name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {},
      rules: {
        company_name: [{ required: true, message: '请输入加工商名称', trigger: 'blur' }],
        customer_name: [{ required: true, message: '请输入关联客户', trigger: 'blur' }]
      },
      customerListData: []
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getCustomerList()
  },
  methods: {
    // 获取客户列表
    getCustomerList(query = '') {
      this.$api({
        method: 'post',
        url: '/admin/customers/search',
        data: {
          filterData: {
            customer_name: query
          }
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.customerListData = res.data.page.list
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.popupCancel()
          // 编辑加工商uuid，与客户进行关联
          this.$confirm('将会同步客户UUID为加工商UUID，不可恢复！', '提示')
            .then(() => {
              this.$api({
                method: 'post',
                url: '/admin/gcvip/processor/changeUuid',
                data: {
                  changeUuidForm: this.selfData
                }
              }).then((res) => {
                if (res.data.state === 'ok') {
                  this.$parent.getListData()
                  this.$message.success('同步成功')
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            })
            .catch(() => {})
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
