<template>
  <el-dialog
    title="采购付款记录"
    width="1200px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.formData"
      />
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn"
        :data="selfDetail.list"
        :show-footer="!!selfDetail.list.length"
      >
        <!-- slot_default -->
        <template #default_fu_kuan_date="{ row }">
          {{ row.fu_kuan_date && row.fu_kuan_date.slice(0, 10) }}
        </template>
        <template #default_fu_kuan_pin_zheng="{ row }">
          <e-upload-image
            v-if="row.fu_kuan_pin_zheng"
            size="30px"
            disabled
            :list.sync="row.fu_kuan_pin_zheng"
          />
        </template>

        <template #default_operate="{ row, rowIndex }">
          <vxe-button
            icon="el-icon-delete"
            title="删除"
            circle
            @click="itemDelete(row.id, rowIndex)"
          />
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    caiGouBillId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        formData: {},
        list: []
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      formColumn: [
        {
          type: 'text',
          title: '采购单号',
          field: 'cai_gou_code',
          disabled: true
        },
        {
          type: 'text',
          title: '供应商',
          field: 'supplier_company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '采购金额(元)',
          field: 'sum_cai_gou_amount',
          disabled: true
        },
        {
          type: 'text',
          title: '欠款金额(元)',
          field: 'qian_kuan_money',
          disabled: true
        }
      ],
      tableColumn: [
        {
          type: 'seq',
          title: '序号'
        },
        {
          title: '付款单号',
          field: 'fu_kuan_code'
        },
        {
          title: '付款日期',
          field: 'fu_kuan_date',
          slots: { default: 'default_fu_kuan_date' }
        },
        {
          title: '实付金额(元)',
          field: 'shi_fu_money'
        },
        {
          title: '付款账户',
          field: 'fu_kuan_account'
        },
        {
          title: '付款凭证',
          field: 'fu_kuan_pin_zheng',
          slots: { default: 'default_fu_kuan_pin_zheng' }
        },
        {
          title: '操作',
          slots: { default: 'default_operate' }
        }
      ]
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/caiGouFuKuan/detail',
        params: {
          id: this.caiGouBillId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfDetail = this.$util.deepCopy(res.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-删除
    itemDelete(id, rowIndex) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/caiGouFuKuan/delete',
        params: {
          id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.$message.success('删除成功')
            this.selfDetail.list.splice(rowIndex, 1)
            this.$emit('success')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="less" scoped></style>
