<template>
  <table class="print-table">
    <thead>
      <tr>
        <th>任务顺序</th>
        <th>任务名称</th>
        <th>任务负责人</th>
        <th>任务数量</th>
        <th>计划开工日期</th>
        <th>计划完工日期</th>
        <th>备注</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in selfData" :key="index">
        <td>{{ item.ren_wu_order_id }}</td>
        <td>{{ item.ren_wu_name }}</td>
        <td>{{ item.ren_wu_fu_ze_ren }}</td>
        <td>{{ item.ren_wu_count }}</td>
        <td>{{ item.ren_wu_ji_hua_kai_gong_date }}</td>
        <td>{{ item.ren_wu_ji_hua_wan_gong_date }}</td>
        <td>{{ item.ren_wu_bei_zhu }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfData: []
    }
  },
  mounted() {
    this.selfData = this.$util.deepCopy(this.data)
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.print-table {
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  .text-right {
    text-align: right;
  }
}
.print-table caption {
  padding-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.print-table th,
.print-table td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}
.print-table th {
  font-weight: normal;
  background: #e0e5eb;
}
</style>
