<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <el-button type="danger" size="mini" @click="itemDelete">删除</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 187"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :merge-cells="mergeCell"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.chu_ku_yu_yue_time"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_chu_ku_yu_yue_time="{ row }">
        {{ row.chu_ku_yu_yue_time ? row.chu_ku_yu_yue_time.slice(0, 10) : '' }}
      </template>
      <template #default_zhuang_che_photo_list="{ row }">
        <e-upload-image
          v-if="row.zhuang_che_photo_list"
          size="30px"
          disabled
          :list.sync="row.zhuang_che_photo_list"
        />
      </template>
      <template #default_cun_fang_wei_zhi_list="{ row }">
        <e-upload-image
          v-if="row.cun_fang_wei_zhi_list"
          size="30px"
          disabled
          :list.sync="row.cun_fang_wei_zhi_list"
        />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: true,

      // filter
      filterData: {
        chu_ku_yu_yue_time: [this.$util.getDate().monthDay, this.$util.getDate().today],
        chu_ku_status: '已审核'
      },
      sortData: {
        chu_ku_stock_id: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/chuKu/searchHeard',
      mergeCell: [],
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          width: 40,
          isMerge: true
        },
        {
          title: 'ID',
          field: 'id',
          width: 60,
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '出库单号',
          field: 'chu_ku_code',
          width: 110,
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '预约出库日期',
          field: 'chu_ku_yu_yue_time',
          width: 180,
          isMerge: true,
          slots: { header: 'header_daterange', default: 'default_chu_ku_yu_yue_time' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '预约车牌号',
          field: 'driver_car_code',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '装车照片',
          isMerge: true,
          slots: { default: 'default_zhuang_che_photo_list' }
        },
        {
          title: '存放位置',
          isMerge: true,
          slots: { default: 'default_cun_fang_wei_zhi_list' }
        },
        {
          title: '出库备注',
          field: 'chu_ku_remark',
          isMerge: true,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '品名',
          field: 'craft',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '材质',
          field: 'texture',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '规格',
          width: 120,
          field: 'specification',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单位',
          field: 'unit',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '出库数量',
          field: 'chu_ku_stock_counts',
          slots: { header: 'header_autocomplete' }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      }
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/chuKu/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.mergeCell = this.$tool.getMergeCellConfigList(
              this.tableData,
              this.tableColumn,
              'chu_ku_code'
            )
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        this.$refs.refTable.setRadioRow(row, !isCurrentRowRadio) // 选中radio
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-删除
    itemDelete() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemDeleteAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-删除
    itemDeleteAction(row) {
      this.$confirm(`出库单id：${row.id}，是否删除选中出库单?`, '提示')
        .then(() => {
          this.itemDeleteConfirm(row.id)
        })
        .catch(() => {})
    },
    // 项-删除-确认
    itemDeleteConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/chuKu/delete',
        params: {
          id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('删除成功')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
