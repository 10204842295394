<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="tableOptions.loading">
      <template #buttons>
        <el-button type="success" size="mini" @click="listSync">同步钉钉用户</el-button>
        <el-button
          v-if="$tool.hasControl(['/dingding/saveOrUpdate'])"
          type="primary"
          size="mini"
          @click="itemAdd"
          >发起申请</el-button
        >
        <el-button
          v-if="$tool.hasControl(['/dingding/saveOrUpdate'])"
          type="primary"
          size="mini"
          @click="itemEdit"
          >修改申请</el-button
        >
        <el-button
          v-if="$tool.hasControl(['/admin/gcvip/dingding/doAction'])"
          type="danger"
          size="mini"
          @click="itemReject"
          >审批拒绝</el-button
        >
        <el-button
          v-if="$tool.hasControl(['/admin/gcvip/dingding/doAction'])"
          type="success"
          size="mini"
          @click="itemPass"
          >审批通过</el-button
        >
      </template>
    </vxe-toolbar>

    <vxe-grid
      ref="refTable"
      v-bind="tableOptions"
      @cell-dblclick="itemEdit"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData[column.field]"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_status="{ row }">
        <span :style="{ color: statusStyle(row) }"> {{ row.status }} </span>
      </template>
      <template #default_tiao_xiu_date_list="{ row }">
        <div v-if="row.tiao_xiu_date_list.length">
          <div>{{ row.tiao_xiu_date_list[0] }}</div>
          <div>{{ row.tiao_xiu_date_list[1] }}</div>
        </div>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="tableOptions.pagerConfig" @page-change="pageChange" />
      </template>
    </vxe-grid>
    <!-- 申请增改 -->
    <PopupAdd v-if="isShowPopupAdd" :show.sync="isShowPopupAdd" @success="searchListData" />
    <PopupEdit
      v-if="isShowPopupEdit"
      :show.sync="isShowPopupEdit"
      :detail="activeDetail"
      @success="searchListData"
    />
  </div>
</template>

<script>
import PopupAdd from './popup-add'
import PopupEdit from './popup-edit'
export default {
  components: {
    PopupAdd,
    PopupEdit
  },
  data() {
    return {
      // filter
      filterData: {
        create_date: [this.$util.getDate().monthDay, this.$util.getDate().today]
      },

      // table
      headerFilterUrl: '/admin/gcvip/dingding/searchTiaoXiuHeard',
      tableOptions: {
        loading: true,
        size: 'mini',
        border: true,
        'highlight-current-row': true,
        'max-height': this.$util.getViewHeight() - 148,
        pagerConfig: {
          ...this.$constant.page
        },
        columns: [
          {
            fixed: 'left',
            type: 'radio',
            width: 40
          },
          {
            field: 'create_date',
            title: '提交日期',
            width: 180,
            slots: { header: 'header_daterange' }
          },
          {
            field: 'nick_name',
            title: '姓名',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'user_name',
            title: '手机号',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'qing_jia_type',
            title: '请假类型',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'status',
            title: '审批状态',
            slots: { header: 'header_autocomplete', default: 'default_status' }
          },
          {
            field: 'tiao_xiu_date_list',
            title: '调休日期',
            width: 180,
            slots: { header: 'header_daterange', default: 'default_tiao_xiu_date_list' }
          },
          {
            field: 'tiao_xiu_date_day_str',
            title: '休息时长',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'message',
            title: '调休事由',
            slots: { header: 'header_autocomplete' }
          }
        ],
        data: []
      },
      activeDetail: null,
      isShowPopupAdd: false,
      isShowPopupEdit: false,
      isShowPopupReject: false,
      isShowPopupPass: false
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    statusStyle(row) {
      let result = ''
      if (row.status === '待审核') result = '#66b1ff'
      if (row.status === '已拒绝') result = '#f45'
      if (row.status === '已审核') result = '#67c23a'
      return result
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.tableOptions.pagerConfig.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.tableOptions.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dingding/searchTiaoXiu',
        data: {
          currentPage: this.tableOptions.pagerConfig.currentPage,
          pageSize: this.tableOptions.pagerConfig.pageSize,
          filterData: this.filterData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.tableOptions.data = res.data.page.list
            this.tableOptions.pagerConfig.pageSize = res.data.page.pageSize
            this.tableOptions.pagerConfig.total = res.data.page.totalRow
            this.tableOptions.pagerConfig.currentPage = res.data.page.pageNumber
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
        .then(() => {
          this.tableOptions.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.tableOptions.pagerConfig.currentPage = currentPage
      this.tableOptions.pagerConfig.pageSize = pageSize
      this.getListData()
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 同步钉钉用户
    listSync() {
      let pickerDate = []
      if (this.filterData.create_date.length) {
        pickerDate = [
          `${this.filterData.create_date[0]} 00:00:00`,
          `${this.filterData.create_date[1]} 23:59:59`
        ]
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dingding/getUserIdByDingding',
        data: {
          pickerDate
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('钉钉用户同步成功')
          this.getListData()
        }
      })
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupAdd = true
    },
    // 项-修改
    itemEdit() {
      let row = this.getRadioRecord()
      if (row) {
        this.activeDetail = this.$util.deepCopy(row)
        this.isShowPopupEdit = true
      } else {
        this.activeDetail = null
        this.itemChoosePrompt()
      }
    },
    // 项-审批拒绝
    itemReject() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemRejectAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-审批拒绝
    itemRejectAction(row) {
      this.$confirm(
        `姓名：${row.nick_name}，调休日期：${row.tiao_xiu_date_list[0]}至${row.tiao_xiu_date_list[1]}，是否驳回该审批?`,
        '提示'
      )
        .then(() => {
          this.itemRejectConfirm(row.id)
        })
        .catch(() => {})
    },
    // 项-审批拒绝-确认
    itemRejectConfirm(id) {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dingding/doAction',
        data: {
          id,
          status: '已拒绝'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审批已拒绝')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 项-审批通过
    itemPass() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemPassAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-审批通过
    itemPassAction(row) {
      this.$confirm(
        `姓名：${row.nick_name}，调休日期：${row.tiao_xiu_date_list[0]}至${row.tiao_xiu_date_list[1]}，是否通过该审批?`,
        '提示'
      )
        .then(() => {
          this.itemPassConfirm(row.id)
        })
        .catch(() => {})
    },
    // 项-审批通过-确认
    itemPassConfirm(id) {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dingding/doAction',
        data: {
          id,
          status: '已审核'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('审批已通过')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
