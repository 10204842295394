<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <el-button type="success" size="mini" @click="itemAdd">新增账户</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      :loading="loading"
      :data="tableDataMain"
      :columns="tableColumn"
      :max-height="this.$util.getViewHeight() - 150"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_operate="{ row }">
        <template>
          <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        </template>
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 银行账号增改弹窗 -->
    <PopupBank
      v-if="isShowPopupBank"
      :show.sync="isShowPopupBank"
      :data.sync="itemObj"
      @success="searchListData"
    />
  </div>
</template>

<script>
import PopupBank from './popup-bank'
export default {
  components: { PopupBank },
  data() {
    return {
      loading: true,

      keyword: '',
      filterData: {},
      sortData: {
        id: 'asc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/bank/searchHeard',
      tableColumn: [
        {
          field: 'id',
          title: 'ID',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'bank_short_name',
          title: '账户名称',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'bank_code',
          title: '银行账号',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'bank_company',
          title: '开户名',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'bank_name',
          title: '开户行',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'bank_type',
          title: '账户备注',
          slots: { header: 'header_autocomplete' }
        },
        { title: '操作', width: 160, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      },
      isShowPopupBank: false,
      itemDefault: {
        id: '',
        bank_short_name: '',
        bank_code: '',
        bank_company: '',
        bank_name: '',
        bank_type: ''
      },
      itemObj: {}
    }
  },
  created() {
    this.initItemObj()
    this.getListData()
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取数据列表
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/bank/list',
        data: {
          keyword: this.keyword,
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableDataMain = res.data.page.list
            this.pageOptions.pageSize = res.data.page.pageSize
            this.pageOptions.total = res.data.page.totalRow
            this.pageOptions.currentPage = res.data.page.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupBank = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupBank = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$confirm(`id：${row.id}，您确定要删除该数据?`, '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/bank/delete',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
