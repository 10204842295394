import { render, staticRenderFns } from "./print-head.vue?vue&type=template&id=385a85c1&scoped=true"
import script from "./print-head.vue?vue&type=script&lang=js"
export * from "./print-head.vue?vue&type=script&lang=js"
import style0 from "./print-head.vue?vue&type=style&index=0&id=385a85c1&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "385a85c1",
  null
  
)

export default component.exports