<template>
  <el-dialog title="采购付款" width="900px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <div class="f-s-12 m-b-10" style="margin-left: 66px">
        <span class="m-r-10">
          <span class="c-black">采购单号</span>
          <span class="c-gray-light p-l-5">{{ selfData.cai_gou_code }}</span>
        </span>
        <span class="m-r-10">
          <span class="c-black">供应商</span>
          <span class="c-gray-light p-l-5">{{ selfData.supplier_company_name }}</span>
        </span>
        <span class="m-r-10">
          <span class="c-black">采购金额(元)</span>
          <span class="c-gray-light p-l-5">{{ selfData.sum_cai_gou_amount }}</span>
        </span>
        <span class="m-r-10">
          <span class="c-black">欠款金额(元)</span>
          <span class="c-gray-light p-l-5">{{ selfData.qian_kuan_money }}</span>
        </span>
      </div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfData"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">保存提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    caiGouBillId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        fu_kuan_code: '',
        fu_kuan_date: '',
        shi_fu_money: '',
        fu_kuan_account: '',
        fu_kuan_pin_zheng: []
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'date',
          title: '付款日期',
          field: 'fu_kuan_date'
        },
        {
          type: 'text',
          title: '实付金额(元)',
          field: 'shi_fu_money',
          required: true
        },
        {
          type: 'select',
          title: '付款账户',
          field: 'fu_kuan_account',
          options: []
        },
        {
          type: 'upload',
          title: '付款凭证',
          field: 'fu_kuan_pin_zheng',
          isDelete: true
        }
      ],
      rules: {
        shi_fu_money: [{ required: true, message: '请输入实付金额' }]
      },
      bankList: []
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
    this.getBankList()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/caiGouFuKuan/buildBill',
        params: {
          id: this.caiGouBillId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfData = this.$util.deepCopy(res.data.data)
          this.selfData.shi_fu_money = ''
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取收款账号列表
    getBankList() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/bank/list',
        data: {
          currentPage: 1,
          pageSize: this.$constant.pageMax,
          sortData: {
            id: 'desc'
          }
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.bankList = this.$util.deepCopy(res.data.page.list)
          this.formColumn.forEach((formItem) => {
            if (formItem.field === 'fu_kuan_account') {
              formItem.options = this.bankList.map((bankItem) => {
                return {
                  key: bankItem.bank_name,
                  val: bankItem.bank_name
                }
              })
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/caiGouFuKuan/saveOrUpdate',
            data: {
              data: this.selfData
            }
          })
            .then((res) => {
              if (res.data.state === 'ok') {
                this.popupCancel()
                this.$emit('success')
                this.$message.success('保存成功')
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              this.$message.error(error)
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
