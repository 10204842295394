<template>
  <el-dialog title="报工" width="1100px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <div class="f-bold m-b-10">所需物料</div>
      <vxe-grid
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :merge-cells="mergeCell"
        :columns="tableColumn1"
        :data="selfDetail.need_list"
      >
      </vxe-grid>
      <div class="f-bold m-y-10">报工</div>
      <vxe-grid
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :edit-rules="validRules"
        :columns="tableColumn2"
        :data="selfDetail.bao_gong_list"
      >
        <!-- slot_default -->
        <template #default_bao_gong_date="{ row }">
          {{ row.bao_gong_date ? row.bao_gong_date.slice(0, 10) : '' }}
        </template>
        <!-- edit -->
        <template #edit_bao_gong_date="{ row }">
          <e-date-picker
            type="date"
            fontSize="11px"
            width="120px"
            placeholder="选择日期"
            clearable
            :value.sync="row.bao_gong_date"
          />
        </template>
        <template #edit_bao_gong_count="{ row }">
          <el-input v-model="row.bao_gong_count" size="mini" placeholder="请输入" clearable />
        </template>
        <template #edit_cang_ku="{ row }">
          <el-select v-model="row.cang_ku" size="mini" filterable allow-create default-first-option>
            <el-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
        </template>
        <template #edit_bao_gong_ren="{ row }">
          <el-select
            v-model="row.bao_gong_ren"
            size="mini"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="(item, index) in saleList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
        </template>
        <template #edit_ru_ku_code="{ row }">
          <el-input v-model="row.ru_ku_code" size="mini" placeholder="请输入" clearable />
        </template>

        <!-- 操作 -->
        <template #default_do="{ rowIndex }">
          <el-link class="m-r-10" type="success" @click="rowAdd">增行</el-link>
          <el-link
            v-if="selfDetail.bao_gong_list.length > 1"
            class="m-r-10"
            type="danger"
            @click="rowDelete(rowIndex)"
          >
            删行
          </el-link>
        </template>

        <!-- 数据为空 -->
        <template #empty>
          <div class="flex flex-center">
            <span class="f-s-14 c-gray-light">暂无数据</span>
            <el-link class="m-l-10" type="success" @click="rowAdd">增行</el-link>
          </div>
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      mergeCell: [],
      selfDetail: {
        data: {},
        need_list: [],
        bao_gong_list: []
      },
      tableColumn1: [
        {
          title: '物料号',
          field: 'wu_liao_code',
          isMerge: true
        },
        {
          title: '品名',
          field: 'craft',
          isMerge: true
        },
        {
          title: '材质',
          field: 'texture',
          isMerge: true
        },
        {
          title: '规格',
          field: 'sales_specification',
          isMerge: true
        },
        {
          title: '单位',
          field: 'unit',
          isMerge: true
        },
        {
          title: '数量',
          field: 'counts'
        },
        {
          title: '仓库',
          field: 'cang_ku'
        }
      ],
      tableColumn2: [
        {
          title: '报工日期',
          field: 'bao_gong_date',
          slots: { default: 'default_bao_gong_date', edit: 'edit_bao_gong_date' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '报工数量',
          field: 'bao_gong_count',
          slots: { edit: 'edit_bao_gong_count' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '仓库',
          field: 'cang_ku',
          slots: { edit: 'edit_cang_ku' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '报工人',
          field: 'bao_gong_ren',
          slots: { edit: 'edit_bao_gong_ren' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '入库单号',
          field: 'ru_ku_code',
          slots: { edit: 'edit_ru_ku_code' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '操作',
          slots: { default: 'default_do' }
        }
      ],
      validRules: {
        bao_gong_count: [{ required: true }]
      },
      warehouseList: [],
      saleList: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getWarehouseList()
    this.getSaleList()
    this.getDetailData()
  },
  methods: {
    // 获取仓库列表
    getWarehouseList() {
      this.$api({
        method: 'post',
        url: '/admin/warehouseSetting',
        data: {
          currentPage: 1,
          pageSize: this.$constant.pageMax,
          sortData: {
            id: 'asc'
          }
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((warehouseItem) => {
            return {
              key: warehouseItem.name,
              val: warehouseItem.name
            }
          })
          this.warehouseList = result
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取业务员列表
    getSaleList() {
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          filterData: {
            role_name: '业务员'
          },
          pageSize: this.$constant.pageMax
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.nick_name,
              val: item.nick_name,
              id: item.account_id
            }
          })
          this.saleList = result
        } else {
          return
        }
      })
    },
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/gongDan/getRenWu',
        params: {
          ren_wu_id: this.data.ren_wu_id,
          type: '报工'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfDetail = this.$util.deepCopy(res.data)
          if (!this.selfDetail.bao_gong_list.length) this.rowAdd() // 数据为空，默认补一行
          this.mergeCell = this.$tool.getMergeCellConfigList(
            this.selfDetail.need_list,
            this.tableColumn1,
            'wu_liao_code'
          )
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush() {
      let isPassRenWuCount = this.selfDetail.bao_gong_list.every(
        (item) => !!Number(item.bao_gong_count)
      )
      if (!isPassRenWuCount) {
        this.$message.error('报工数量必须>0')
        return
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/gongDan/saveOrUpdateRenWu',
        data: this.selfDetail
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 行-增行
    rowAdd() {
      let result = {
        bao_gong_date: '',
        bao_gong_count: '',
        cang_ku: '',
        bao_gong_ren: '',
        ru_ku_code: ''
      }
      this.selfDetail.bao_gong_list.push(result)
    },
    // 行-删行
    rowDelete(index) {
      this.selfDetail.bao_gong_list.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped></style>
