<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <el-button type="primary" size="mini" @click="itemAudit">审核收款</el-button>
        <el-button type="default" size="mini" @click="itemDetail">查看</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 150"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      @cell-dblclick="itemAudit"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.bill_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_bill_date="{ row }">
        {{ row.bill_date.slice(0, 10) }}
      </template>
      <template #default_bill_code="{ row }">
        <el-link type="primary" @click="itemDetail"> {{ row.bill_code }} </el-link>
      </template>
      <template #default_cash_status="{ row }">
        <span v-if="row.cash_status === '已提交'" :style="{ color: '#999' }">
          {{ row.cash_status }}
        </span>
        <span v-if="row.cash_status === '待审核'" :style="{ color: '#66b1ff' }">
          {{ row.cash_status }}
        </span>
        <span v-if="row.cash_status === '已审核'" :style="{ color: '#67c23a' }">
          {{ row.cash_status }}
        </span>
      </template>
      <template #default_bill_img="{ row }">
        <e-upload-image
          v-if="row.bill_img"
          size="30px"
          disabled
          :limit="1"
          :list.sync="row.bill_img"
        />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 开收款单 -->
    <PopupAudit
      v-if="isShowPopupAudit"
      :show.sync="isShowPopupAudit"
      :detailId="activeId"
      @success="searchListData"
    />
  </div>
</template>

<script>
import PopupAudit from './popup-audit'
export default {
  components: {
    PopupAudit
  },
  data() {
    return {
      loading: true,

      // filter
      filterData: {
        bill_date: [this.$util.getDate().monthDay, this.$util.getDate().today]
      },
      sortData: {
        bill_id: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/reportBill/searchHeard',
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          width: 40
        },
        {
          title: 'ID',
          field: 'bill_id',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '销售日期',
          field: 'bill_date',
          width: 180,
          slots: { header: 'header_daterange', default: 'default_bill_date' }
        },
        {
          title: '销售单号',
          field: 'bill_code',
          slots: { header: 'header_autocomplete', default: 'default_bill_code' }
        },
        {
          title: '客户名称',
          field: 'company_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '审核状态',
          field: 'cash_status',
          slots: {
            header: 'header_autocomplete',
            default: 'default_cash_status'
          }
        },
        {
          title: '待审核金额',
          field: 'dai_shen_he_amount',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '合同应收',
          field: 'sales_amount_total',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '合同已收',
          field: 'amount_received',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '合同未收',
          field: 'un_amount_received',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '开票',
          field: 'is_tax',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '收款预览',
          field: 'bill_img',
          slots: { header: 'header_autocomplete', default: 'default_bill_img' }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      },
      totalObj: {
        sales_amount_total_all: '',
        amount_received_all: '',
        un_amount_received_all: ''
      },

      isShowPopupAudit: false,
      activeId: null
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/reportBill/search',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            let list = data.list.map((itemSt) => {
              let bill_img = []
              itemSt.collection_list.forEach((itemNd) => {
                itemNd.cash_photo_list.forEach((itemRd) => {
                  bill_img.push(itemRd)
                })
              })
              return {
                ...itemSt,
                bill_img
              }
            })
            this.tableData = list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.totalObj.sales_amount_total_all = res.data.sales_amount_total_all
            this.totalObj.amount_received_all = res.data.amount_received_all
            this.totalObj.un_amount_received_all = res.data.un_amount_received_all
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (column.field === 'sales_amount_total') return this.totalObj.sales_amount_total_all
          if (column.field === 'amount_received') return this.totalObj.amount_received_all
          if (column.field === 'un_amount_received') return this.totalObj.un_amount_received_all
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-详情
    itemDetail() {
      setTimeout(() => {
        let row = this.getRadioRecord()
        if (row) {
          this.itemDetailAction(row.id)
        } else {
          this.itemChoosePrompt()
        }
      }, 100)
    },
    // 项-详情-执行
    itemDetailAction(id) {
      window.open(`/finance/payin/detail?id=${id}`, '_blank')
    },
    // 项-审核
    itemAudit() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemAuditAction(row.id)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-审核-执行
    itemAuditAction(id) {
      this.isShowPopupAudit = true
      this.activeId = id
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
