<template>
  <el-dialog title="开送货单" width="1000px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.formData"
      />
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn"
        :data="selfDetail.list"
        :show-footer="!!selfDetail.list.length"
        :footer-method="footerMethod"
      >
        <template #edit_song_huo_stock_counts="{ row }">
          <el-input
            v-model="row.song_huo_stock_counts"
            type="number"
            size="mini"
            clearable
            @blur="countsBlur(row)"
          />
        </template>
        <template #default_pinming="{ row }">
          {{ row.craft }}
          {{ row.texture }}
        </template>
        <template #edit_song_huo_stock_remark="{ row }">
          <el-input v-model="row.song_huo_stock_remark" size="mini" clearable />
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush(false)">提交审核</el-button>
      <el-button size="small" type="primary" @click="popupPush(true)"> 提交并打印 </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    billId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        formData: {
          bill_id: '', // 核算单id
          bill_code: '', // 核算单编号
          company_id: '', // 客户id
          company_name: '', // 客户名称
          company_uuid: '', // 客户uuid
          song_huo_date: this.$XEUtils.toDateString(this.$XEUtils.now(), 'yyyy-MM-dd'), // 送货日期
          song_huo_bill_code: '', // 送货单号
          saler_name: '', // 业务员
          saler_id: '', // 业务员id
          jiao_huo_type: '送货上门', // 交货方式
          ying_shou_yu_e: '', // 应收款余额
          linkman: '', // 联系人
          linkman_phone: '', // 联系电话
          address: '', // 送货地址
          song_huo_bill_remark: '', // 送货备注
          driver_name: '', // 司机
          driver_phone: '', // 司机电话
          driver_car_code: '', // 车牌号
          kuai_di_code: '', // 快递单号
          kuai_di_remark: '' // 快递备注
        },
        list: []
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      isHideSonghuo: true,
      isHideKuaidi: true,
      tableColumn: [
        {
          title: '序号',
          type: 'seq'
        },
        {
          title: '物料号',
          field: 'wu_liao_code'
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code'
        },
        {
          title: '品名',
          slots: { default: 'default_pinming' }
        },
        {
          title: '产品规格',
          field: 'specification'
        },
        {
          title: '单位',
          field: 'unit'
        },
        {
          title: '销售单价(元)',
          field: 'song_huo_stock_price'
        },
        {
          title: '送货数量',
          field: 'song_huo_stock_counts',
          slots: { edit: 'edit_song_huo_stock_counts' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '送货金额(元)',
          field: 'song_huo_stock_amount'
        },
        {
          title: '商品备注',
          field: 'song_huo_stock_remark',
          slots: { edit: 'edit_song_huo_stock_remark' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ]
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    },

    formColumn() {
      let result = [
        {
          type: 'date',
          title: '送货日期',
          field: 'song_huo_date',
          required: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'text',
          title: '送货单号',
          field: 'song_huo_bill_code',
          placeholder: '自动生成',
          disabled: true
        },
        {
          type: 'text',
          title: '业务员',
          field: 'saler_name',
          disabled: true
        },
        {
          type: 'select',
          title: '交货方式 ',
          field: 'jiao_huo_type',
          required: true,
          options: [
            {
              key: '送货上门', // 显示司机、电话、车牌号
              val: '送货上门'
            },
            {
              key: '快递物流', // 显示快递单号、快递备注
              val: '快递物流'
            },
            {
              key: '自提',
              val: '自提'
            }
          ]
        },
        {
          type: 'text',
          title: '应收款余额',
          field: 'ying_shou_yu_e',
          disabled: true
        },
        {
          type: 'text',
          title: '客户名称',
          field: 'company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '联系人',
          field: 'linkman'
        },
        {
          type: 'text',
          title: '联系电话',
          field: 'linkman_phone'
        },
        {
          type: 'text',
          title: '送货地址',
          field: 'address',
          width: '50%'
        },
        {
          type: 'text',
          title: '送货备注',
          field: 'song_huo_bill_remark',
          width: '50%'
        },
        {
          type: 'text',
          title: '司机',
          field: 'driver_name',
          hide: this.isHideSonghuo
        },
        {
          type: 'text',
          title: '司机电话',
          field: 'driver_phone',
          hide: this.isHideSonghuo
        },
        {
          type: 'text',
          title: '车牌号',
          field: 'driver_car_code',
          hide: this.isHideSonghuo
        },
        {
          type: 'text',
          title: '快递单号',
          field: 'kuai_di_code',
          hide: this.isHideKuaidi
        },
        {
          type: 'text',
          title: '快递备注',
          field: 'kuai_di_remark',
          hide: this.isHideKuaidi
        }
      ]
      return result.filter((item) => !item.hide)
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    'selfDetail.formData.jiao_huo_type': {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        if (newVal === '送货上门') {
          this.isHideSonghuo = false
          this.isHideKuaidi = true
        }
        if (newVal === '快递物流') {
          this.isHideSonghuo = true
          this.isHideKuaidi = false
        }
        if (newVal === '自提') {
          this.isHideSonghuo = true
          this.isHideKuaidi = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/songHuo/buildSongHuoBill',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfDetail.formData.bill_id = res.data.formData.bill_id
          this.selfDetail.formData.bill_code = res.data.formData.bill_code
          this.selfDetail.formData.company_id = res.data.formData.company_id
          this.selfDetail.formData.company_name = res.data.formData.company_name
          this.selfDetail.formData.company_uuid = res.data.formData.company_uuid
          this.selfDetail.formData.saler_name = res.data.formData.saler_name
          this.selfDetail.formData.saler_id = res.data.formData.saler_id
          this.selfDetail.formData.linkman = res.data.formData.linkman
          this.selfDetail.formData.linkman_phone = res.data.formData.linkman_phone
          this.selfDetail.formData.ying_shou_yu_e = res.data.formData.ying_shou_yu_e
          this.selfDetail.list = res.data.list
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 表格底部
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['song_huo_stock_counts', 'song_huo_stock_amount'].includes(column.field)) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 送货数量-失焦
    countsBlur(row) {
      row.song_huo_stock_amount = this.$XEUtils.multiply(
        row.song_huo_stock_price,
        row.song_huo_stock_counts
      )
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush(isPushAndPrint) {
      if (!this.selfDetail.formData.song_huo_date) {
        this.$message.error('请选择送货日期')
        return
      }
      if (!this.selfDetail.formData.jiao_huo_type) {
        this.$message.error('请选择交货方式')
        return
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/songHuo/saveOrUpdateSongHuoBill',
        data: this.selfDetail
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')

            // 项-提交并打印
            if (isPushAndPrint) {
              this.popupPrint(res.data.code)
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 项-打印
    popupPrint(code) {
      window.open(`/sale/delivery/print?code=${code}`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped></style>
