<template>
  <div class="box-dialog">
    <el-dialog :title="`${title}`" :visible.sync="selfShow" :close-on-click-modal="false">
      <vxe-grid
        ref="refTableProcess"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :header-cell-class-name="cellClassName"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :edit-rules="validRules"
        :footer-method="tableFooterMethod"
        :columns="tableColumn"
        :data="selfData.process_list"
        :show-footer="!!selfData.process_list.length"
      >
        <!-- 工艺流程 -->
        <template #default_seq="{ rowIndex }"> 工序{{ rowIndex + 1 }} </template>

        <!-- 加工工艺 -->
        <template #edit_process_name="{ row }">
          <el-select
            v-model="row.process_name"
            size="mini"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="(item, index) in processList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
        </template>

        <!-- 检测标准 -->
        <template #default_test_list="{ row, rowIndex }">
          <el-link v-if="row.process_name" type="primary" @click="openPopupProcessTest(rowIndex)">
            {{ row.test_list && row.test_list.length ? '编辑' : '新增' }}
          </el-link>
        </template>
        <template #edit_test_list="{ row, rowIndex }">
          <el-link v-if="row.process_name" type="primary" @click="openPopupProcessTest(rowIndex)">
            {{ row.test_list && row.test_list.length ? '编辑' : '新增' }}
          </el-link>
        </template>

        <!-- 加工单价 -->
        <template #edit_process_price="{ row }">
          <el-input
            v-model="row.process_price"
            type="number"
            size="mini"
            clearable
            @blur="processPriceBlur"
          />
        </template>

        <!-- 操作 -->
        <template #default_do="{ rowIndex }">
          <el-link class="m-r-10" type="success" :disabled="disabled" @click="rowAdd">增行</el-link>
          <el-link
            v-if="selfData.process_list.length > 1"
            class="m-r-10"
            type="danger"
            :disabled="disabled"
            @click="rowDelete(rowIndex)"
          >
            删行
          </el-link>
        </template>

        <!-- 数据为空 -->
        <template #empty>
          <div class="flex flex-center">
            <span class="f-s-14 c-gray-light">暂无数据</span>
            <el-link class="m-l-10" type="success" :disabled="disabled" @click="rowAdd"
              >增行</el-link
            >
          </div>
        </template>
      </vxe-grid>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
    <PopupProcessTest
      v-if="isShowPopupProcessTest"
      :show.sync="isShowPopupProcessTest"
      :data.sync="selfData.process_list[activeIndex]"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import PopupProcessTest from './popup-process-test'
import math from './math'
export default {
  inject: ['getParentTableObj'],
  components: {
    PopupProcessTest
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortable: null,
      selfShow: false,
      validRules: {
        // process_name: [{ required: true }],
        // test_list: [{ required: true }],
        // process_price: [{ required: true }]
      },
      tableColumn: [
        {
          title: '物料号',
          field: 'wu_liao_code',
          formatter: ({ cellValue }) => {
            return this.$tool.autoGenerate(cellValue)
          }
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code',
          formatter: ({ cellValue, row }) => {
            let result = ''
            if (cellValue.length) {
              result = cellValue.join(',')
            } else {
              if (!row.bill_id) result = this.$tool.autoGenerate('')
            }
            return result
          }
        },
        {
          title: '工艺流程',
          slots: { default: 'default_seq' }
        },
        {
          title: '加工工艺',
          field: 'process_name',
          slots: { edit: 'edit_process_name' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '检测标准',
          slots: { default: 'default_test_list', edit: 'edit_test_list' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '加工单价',
          field: 'process_price',
          slots: { edit: 'edit_process_price' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '数量',
          field: 'process_count'
        },
        {
          title: '加工金额',
          field: 'process_amount'
        },
        {
          title: '操作',
          slots: { default: 'default_do' }
        }
      ],
      selfData: {},
      processList: [],
      isShowPopupProcessTest: false, // 是否显示产品工艺管控卡弹窗
      activeIndex: null // 当前行索引
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    },
    // 标题
    title() {
      let result = this.getParentTableObj().tableBomData.length ? '组装计算' : '加工计算'
      return `${result}(可上下拖动调整排序)`
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getProcessList()
    if (!this.selfData.process_list.length) this.rowAdd() // 数据为空，默认补一行
    this.rowDrop()
  },
  beforeDestroy() {
    if (this.sortable) {
      this.sortable.destroy() // 销毁拖拽排序
    }
  },
  methods: {
    // 行拖拽
    rowDrop() {
      this.$nextTick(() => {
        let $table = this.$refs.refTableProcess
        let $sortContent = $table.$el.querySelector('.body--wrapper>.vxe-table--body tbody')
        this.sortable = Sortable.create($sortContent, {
          handle: 'tr',
          animation: 150,
          onEnd: ({ newIndex, oldIndex }) => {
            let data = this.$util.deepCopy(this.selfData.process_list)
            let oldItem = this.$util.deepCopy(data[oldIndex])
            // 上移
            if (newIndex < oldIndex) {
              if (newIndex === 0) {
                data.splice(oldIndex, 1)
                data.unshift(oldItem) // 顶部
              } else {
                data.splice(oldIndex, 1)
                data.splice(newIndex, 0, oldItem)
              }
            }
            // 下移
            if (newIndex > oldIndex) {
              if (newIndex === data.length - 1) {
                data.push(oldItem) // 底部
                data.splice(oldIndex, 1)
              } else {
                data.splice(newIndex + 1, 0, oldItem)
                data.splice(oldIndex, 1)
              }
            }
            this.selfData.process_list = this.$util.deepCopy(data)
          }
        })
      })
    },
    // 获取材质列表
    getProcessList(query = '') {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/machine/listByAll',
        data: {
          query: query
        }
      }).then((res) => {
        if (res.status === 200) {
          let result = res.data.list.map((item) => {
            return {
              key: item.name,
              val: item.name
            }
          })
          this.processList = result
        }
      })
    },
    // 表格单元格样式设置
    cellClassName({ column }) {
      if (column.slots && column.slots.edit) return 'edit'

      return null
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.popupCancel()
      if (JSON.stringify(this.data) !== JSON.stringify(this.selfData)) {
        this.$emit('update:data', this.$util.deepCopy(this.selfData))
      }
    },
    // 行-增行
    rowAdd() {
      let result = this.$util.deepCopy(this.$constant.tableProcessObj)
      result.process_count = this.data.counts
      this.selfData.process_list.push(result)
      this.$refs.refTableProcess.clearCurrentRow()
    },
    // 行-删行
    rowDelete(index) {
      this.selfData.process_list.splice(index, 1)
      this.$refs.refTableProcess.clearCurrentRow()
      this.processPriceBlur()
    },
    // 材料弹窗
    openPopupProcessTest(index) {
      this.isShowPopupProcessTest = true
      this.activeIndex = index
    },
    // 表格底部
    tableFooterMethod({ columns, data }) {
      // 重新计算行金额，修复金额合计不是最新结果的问题
      data.forEach((item) => {
        let a = Number(item.process_price)
        let b = Number(item.process_count)
        item.process_amount = this.$XEUtils.multiply(a, b)
      })
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['process_price', 'process_amount'].includes(column.field)) {
            return this.$tool.listAddTotal(data, column.field)
          }
        })
      ]
    },
    // 销售单价-失焦
    processPriceBlur() {
      let row = this.selfData
      math.totalRowProcessData(row) // 计算-汇总行加工组装
      math.totalRowSaleData(row) // 计算-汇总行销售
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
