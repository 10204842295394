<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <el-button type="danger" size="mini" @click="itemDelete">删除</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 187"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      :merge-cells="mergeCell"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.ru_ku_bill_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_ru_ku_bill_date="{ row }">
        {{ row.ru_ku_bill_date ? row.ru_ku_bill_date.slice(0, 10) : '' }}
      </template>
      <template #default_ru_ku_code="{ row }">
        <div>{{ row.ru_ku_code }}</div>
        <div>
          <el-link type="primary" @click="itemCheckAll(row)"> 全选当前单号 </el-link>
        </div>
      </template>
      <template #default_cai_gou_code="{ row }">
        <el-link type="primary" @click="itemDetail(row)"> {{ row.cai_gou_code }} </el-link>
      </template>
      <template #default_song_huo_dan_photo_list="{ row }">
        <e-upload-image
          v-if="row.song_huo_dan_photo_list"
          size="30px"
          disabled
          :list.sync="row.song_huo_dan_photo_list"
        />
      </template>
      <template #default_test_standard="{ row }">
        <el-link type="primary" @click="openPopupTest(row)"> 查看 </el-link>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 检测标准 -->
    <b-popup-test v-if="isShowPopupTest" :show.sync="isShowPopupTest" :data="activeObj" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,

      // filter
      filterData: {
        ru_ku_bill_date: [this.$util.getDate().monthDay, this.$util.getDate().today]
      },
      sortData: {
        ru_ku_stock_id: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/caigou/searchHeard',
      mergeCell: [],
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          title: 'ID',
          field: 'ru_ku_stock_id',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '入库日期',
          field: 'ru_ku_bill_date',
          width: 180,
          isMerge: true,
          slots: { header: 'header_daterange', default: 'default_ru_ku_bill_date' }
        },
        {
          title: '入库单号',
          field: 'ru_ku_code',
          width: 110,
          isMerge: true,
          slots: { header: 'header_daterange', default: 'default_ru_ku_code' }
        },
        {
          title: '来源单号',
          field: 'cai_gou_code',
          width: 110,
          slots: { header: 'header_autocomplete', default: 'default_cai_gou_code' }
        },
        {
          title: '供应商',
          field: 'supplier_company_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '送货单图片',
          field: 'song_huo_dan_photo_list',
          slots: { default: 'default_song_huo_dan_photo_list' }
        },
        {
          title: '品名',
          field: 'craft',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '工序',
          field: 'process_order_name'
        },
        {
          title: '工艺',
          field: 'process_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '材质',
          field: 'texture',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '规格',
          width: 120,
          field: 'specification',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单位',
          field: 'unit',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '入库数量',
          field: 'ru_ku_stock_counts',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '仓库',
          field: 'cang_ku',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '检测标准',
          field: 'test_standard',
          width: 60,
          slots: { header: 'header_autocomplete', default: 'default_test_standard' }
        },
        {
          title: '商品备注',
          field: 'ru_ku_stock_remark',
          slots: { header: 'header_autocomplete' }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      },
      totalObj: {
        sales_amount_all: ''
      },

      isShowPopupTest: false, // 是否显示产品工艺管控卡弹窗
      activeList: [],
      activeObj: ''
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/ruku/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.totalObj.sales_amount_all = res.data.sales_amount_all
            this.mergeCell = this.$tool.getMergeCellConfigList(
              this.tableData,
              this.tableColumn,
              'ru_ku_code'
            )
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (column.field === 'cai_gou_stock_amount') return this.totalObj.sales_amount_all
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前单选行
    getCheckboxRecords() {
      return this.$refs.refTable.getCheckboxRecords()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setCheckboxRow(params.row, true) // 选中checkbox
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowCheckbox = this.$refs.refTable.isCheckedByCheckboxRow(row)
        this.$refs.refTable.setCheckboxRow(row, !isCurrentRowCheckbox) // 选中checkbox
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-全选当前单号
    itemCheckAll(row) {
      let rows = this.tableData.filter((item) => item.ru_ku_code === row.ru_ku_code)
      this.$refs.refTable.setCheckboxRow(rows, true)
    },
    // 项-详情
    itemDetail(row) {
      this.$router.push(`/purchase/order-purchase/print?code=${row.cai_gou_code}`)
    },
    // 项-删除
    itemDelete() {
      let rows = this.getCheckboxRecords()
      if (rows.length) {
        this.itemDeleteAction(rows)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-删除
    itemDeleteAction(rows) {
      let ids = rows.map((item) => item.ru_ku_stock_id).join(',')
      this.$confirm(`采购单id：${ids}，是否批量删除选中采购单?`, '提示')
        .then(() => {
          this.itemDeleteConfirm(ids)
        })
        .catch(() => {})
    },
    // 项-删除-确认
    itemDeleteConfirm(ids) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/ruku/delete',
        params: {
          ru_ku_stock_id: ids
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('删除成功')
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 检测标准
    openPopupTest(row) {
      this.activeObj = row
      this.isShowPopupTest = true
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
