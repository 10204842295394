<template>
  <div class="group-box">
    <div v-if="listData.length" class="group-list">
      <div class="group-item" v-for="(itemSt, indexSt) in listData" :key="indexSt">
        <div class="group-top">
          <span>{{ itemSt.title.name }}</span>
          <div v-if="isEdit" class="flex flex-y-center">
            <el-link
              type="primary"
              title="编辑分类"
              :underline="false"
              @click="titleEdit(itemSt.title)"
            >
              编辑
            </el-link>
            <span>丨</span>
            <el-link
              type="danger"
              title="删除分类"
              :underline="false"
              @click="titleDelete(itemSt.title)"
            >
              删除
            </el-link>
          </div>
        </div>
        <div class="group-center">
          <el-input
            size="mini"
            prefix-icon="el-icon-search"
            :placeholder="`筛选${itemSt.title.name}类目`"
            clearable
            v-model="listTitle[indexSt].keyword"
            @input="filterCategory(indexSt, $event)"
          />
          <div class="group-cont">
            <div
              v-for="(itemNd, indexNd) in itemSt.category"
              :key="indexNd"
              :class="{ 'category-item': true, active: itemSt.title.active_id === itemNd.id }"
              @click="categoryChoose(itemNd)"
              v-show="itemNd.isShow"
            >
              <span class="category-name">{{ itemNd.name }}</span>
              <div v-if="isEdit" class="flex">
                <span
                  class="iconfont icon-edit"
                  title="编辑类目"
                  @click.stop="categoryEdit(itemNd)"
                />
                <span
                  class="iconfont icon-delete"
                  title="删除类目"
                  @click.stop="categoryDelete(itemNd)"
                />
                <span v-if="itemNd.has_children" class="iconfont icon-arrow-right c-gray-light" />
                <span v-else class="iconfont icon-right" />
              </div>
              <div v-else>
                <span v-if="itemNd.has_children" class="iconfont icon-arrow-right c-gray-light" />
                <span v-else class="iconfont icon-right" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isEdit" class="group-bottom">
          <div
            v-if="!indexSt || listData[indexSt - 1].title.active_id"
            class="flex flex-y-center"
            title="新增类目"
            @click="categoryAdd(indexSt)"
          >
            <span class="iconfont icon-add" />
            <span>新增类目</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isEdit"
      :class="`title-add ${listData.length ? 'no-border-left' : ''}`"
      title="新增分类"
      @click="titleAdd"
    >
      <span class="iconfont icon-add" />
      <span>{{ listData.length ? '新增下级分类' : '新增一级分类' }}</span>
    </div>
    <PopupCategoryTitleDo
      v-if="isShowPopupCategoryTitleDo"
      :show.sync="isShowPopupCategoryTitleDo"
      :data.sync="objTitle"
      @success="getTitleList"
    />
    <PopupCategoryItemDo
      v-if="isShowPopupCategoryItemDo"
      :show.sync="isShowPopupCategoryItemDo"
      :data.sync="objCategory"
      :categoryName="listTitle[objCategory.level - 1 || 0].name"
      @success="getTitleList"
    />
  </div>
</template>

<script>
import PopupCategoryTitleDo from './popup-category-title-do'
import PopupCategoryItemDo from './popup-category-item-do'
export default {
  components: {
    PopupCategoryTitleDo,
    PopupCategoryItemDo
  },
  props: {
    // 是否可编辑
    isEdit: {
      type: Boolean,
      default: false
    },
    // 是否初始化
    isInit: {
      type: Boolean,
      default: false
    },
    // 选中id列表
    ids: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      listTitle: [],
      listCategory: [],
      objTitle: {},
      objCategory: {},
      isShowPopupCategoryTitleDo: false,
      isShowPopupCategoryItemDo: false
    }
  },
  computed: {
    // 标题和类目的数据列表
    listData() {
      let list = this.listTitle.map((item, index) => {
        // 对应层级是否存在类目
        let hasCategory = index < this.listCategory.length
        let category = hasCategory ? this.$util.deepCopy(this.listCategory[index]) : []

        return {
          title: { ...item },
          category
        }
      })
      this.$emit('success', list)
      return list
    }
  },
  created() {
    this.initTitleList()
  },
  methods: {
    // 初始化标题列表
    initTitleList() {
      let result = this.ids.map((item) => {
        return {
          active_id: item
        }
      })
      this.listTitle = result
      this.getTitleList(this.isInit)
    },
    // 获取标题列表数据
    getTitleList(isInitAcitveId) {
      this.$api({
        methods: 'get',
        url: '/admin/gcvip/column/list'
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let list = res.data.list.map((item, index) => {
              return {
                ...item,
                active_id: this.listTitle[index] ? this.listTitle[index].active_id : '',
                keyword: ''
              }
            })
            setTimeout(() => {
              this.listTitle = this.$util.deepCopy(list)
              this.listTitle.forEach((item, index) => {
                if (!index) {
                  // 一级类目
                  this.getCategoryList(null, 1, isInitAcitveId)
                } else {
                  let parentObj = this.listTitle[index - 1]
                  if (parentObj.active_id) {
                    // 非一级类目，且上级分类的选中类目存在
                    let timeCount = index * 100 // 解决类目按层级顺序进行加载问题
                    setTimeout(() => {
                      this.getCategoryList(parentObj.active_id, item.level)
                    }, timeCount)
                  }
                }
              })
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },
    // 获取类目列表数据
    getCategoryList(id, level, isInitAcitveId) {
      this.$api({
        methods: 'get',
        url: '/admin/gcvip/columnData/list',
        params: {
          id: id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            if (res.data.list.length) {
              let idx = level - 1
              let list = this.$util.deepCopy(res.data.list)
              list.forEach((item) => {
                item.isShow = true
              })
              this.listCategory.splice(idx, 1, list)
              // 初始化设置类目，默认选中所有类目的第一项
              if (isInitAcitveId) {
                let objCategory = this.listCategory[idx][0]
                this.listTitle[idx].active_id = objCategory.id
                if (objCategory.has_children) {
                  this.getCategoryList(objCategory.id, objCategory.level + 1, true)
                }
              }
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },

    // 标题逻辑---------------------------------------------
    // 标题-新增
    titleAdd() {
      let len = this.listTitle.length
      if (!len) {
        // 一级分类
        this.objTitle = {
          level: 1
        }
      } else {
        // 非一级分类
        let { level, name, id } = this.listTitle[len - 1]
        this.objTitle = {
          id: '',
          name: '',
          level: level + 1,
          parent_name: name,
          parent_id: id
        }
      }
      this.isShowPopupCategoryTitleDo = true
    },
    // 标题-编辑
    titleEdit(objTitle) {
      this.objTitle = this.$util.deepCopy(objTitle)
      this.isShowPopupCategoryTitleDo = true
    },
    // 标题-删除
    titleDelete(objTitle) {
      this.$confirm(
        `分类名称：${objTitle.name}，删除后，将无法找回，是否删除该分类包含的所有分类和类目?`,
        '提示'
      )
        .then(() => {
          this.titleDeleteConfirm(objTitle)
        })
        .catch(() => {})
    },
    // 标题-删除-确认
    titleDeleteConfirm(objTitle) {
      this.$api({
        methods: 'get',
        url: '/admin/gcvip/column/delete',
        params: {
          id: objTitle.id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.$message.success(res.data.msg)
            this.getTitleList()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },

    // 标题逻辑---------------------------------------------
    // 类目-新增
    categoryAdd(index) {
      if (!index) {
        // 一级类目
        this.objCategory = {
          level: 1
        }
      } else {
        // 非一级类目，需要先获取上级分类的选中类目
        let data = this.listData[index - 1]
        let parentData = null
        if (data.category) {
          data.category.forEach((item) => {
            if (data.title.active_id === item.id) {
              parentData = this.$util.deepCopy(item)
            }
          })
        }
        let { level, name, id } = parentData
        this.objCategory = {
          id: '',
          name: '',
          level: level + 1,
          parent_name: name,
          parent_id: id
        }
      }

      this.isShowPopupCategoryItemDo = true
    },
    // 类目-编辑
    categoryEdit(objCategory) {
      this.objCategory = this.$util.deepCopy(objCategory)
      this.isShowPopupCategoryItemDo = true
    },
    // 类目-删除
    categoryDelete(objCategory) {
      this.$confirm(
        `类目名称：${objCategory.name}，删除后，将无法找回，是否删除该类目包含的所有类目?`,
        '提示'
      )
        .then(() => {
          this.categoryDeleteConfirm(objCategory)
        })
        .catch(() => {})
    },
    // 类目-删除-确认
    categoryDeleteConfirm(objCategory) {
      this.$api({
        methods: 'get',
        url: '/admin/gcvip/columnData/delete',
        params: {
          id: objCategory.id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.$message.success(res.data.msg)
            // 1.清空该层级以及子孙分类的类目选中
            // 2.清空该层级以及子孙分类的类目列表
            this.listTitle.forEach((item, index) => {
              if (index >= objCategory.level - 1) item.active_id = ''
            })
            this.listCategory.splice(objCategory.level - 1, this.listCategory.length - 1)
            this.getTitleList()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },
    // 类目-选择
    categoryChoose(objCategory) {
      // 1.设置该层级分类的类目选中
      // 2.清空该子孙分类的类目选中和筛选关键字
      // 3.清空该子孙分类的类目列表
      this.listTitle.forEach((item, index) => {
        if (index === objCategory.level - 1) item.active_id = objCategory.id
        if (index > objCategory.level - 1) {
          item.active_id = ''
          item.keyword = ''
        }
      })
      this.listCategory.splice(objCategory.level, this.listCategory.length - 1)
      if (objCategory.has_children) {
        this.getCategoryList(objCategory.id, objCategory.level + 1)
      }
    },
    // 筛选类目
    filterCategory(idx, keyword) {
      this.listCategory[idx].forEach((item) => {
        item.isShow = item.name.includes(keyword)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.group-box {
  position: relative;
  min-height: 28px;
  .group-list {
    display: flex;
    .group-item {
      display: flex;
      flex-direction: column;
      width: 200px;
      line-height: 26px;
      border: 1px solid #e4e7ed;
      & ~ .group-item {
        border-left: none;
      }
      &:first-child {
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-radius: 0 2px 2px 0;
      }
      .group-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        background: #f8f8f9;
      }
      .group-center {
        height: 252px;
        border-top: 1px solid #e4e7ed;
        .group-cont {
          height: 224px;
          overflow-y: auto;
          .scroll-bar();
        }
        /deep/ .el-input {
          height: 28px;
          .el-input__inner {
            height: 28px;
            line-height: 26px;
            border: none;
            border-radius: 0;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #e4e7ed;
          }
        }
        .category-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 28px;
          padding: 0 5px;
          cursor: pointer;
          .icon-right,
          .icon-edit,
          .icon-delete {
            display: none;
          }
          &:hover,
          &.active {
            background: @colorBlue10;
          }
          &.active {
            .category-name,
            .icon-arrow-right,
            .icon-right {
              color: @colorBlue;
            }
            .icon-right,
            .icon-edit,
            .icon-delete {
              display: block;
            }
            .icon-edit:hover,
            .icon-delete:hover {
              color: @colorBlue;
            }
          }
        }
      }
      .group-bottom {
        border-top: 1px solid #e4e7ed;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @colorGreen;
        cursor: pointer;
      }
    }
  }
  .title-add {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 26px;
    color: @colorGreen;
    border: 1px solid #e4e7ed;
    border-radius: 2px;
    cursor: pointer;
    &.no-border-left {
      border-left: none;
    }
  }
  .iconfont {
    cursor: pointer;
    & ~ .iconfont {
      margin-left: 5px;
    }
  }
}
</style>
