<template>
  <div class="page-main">
    <div class="group-st" v-for="(itemSt, indexSt) in listData" :key="indexSt">
      <div class="group-left">
        <CardItem :data="itemSt" :isSplit="false" :idx="indexSt" />
      </div>
      <div class="group-right">
        <CardItem :data="itemSt" :idx="indexSt" />
      </div>
    </div>
  </div>
</template>

<script>
import CardItem from './card-item'
export default {
  components: {
    CardItem
  },
  data() {
    return {
      listData: [
        {
          type: '原料',
          craft: '装饰管1',
          texture: '201',
          sales_specification: '10*1*6000',
          wu_liao_code: '自动生成',
          counts: '100',
          counts_unit: '1',
          unit: '支',
          process_list: [],
          children: []
        },
        {
          type: '原料',
          craft: '装饰管2',
          texture: '304',
          sales_specification: '10*1*6000',
          wu_liao_code: '自动生成',
          counts: '7',
          counts_unit: '1',
          unit: '支',
          process_list: [],
          children: [
            {
              type: '零件',
              craft: '装饰管2',
              texture: '304',
              sales_specification: '10*1*380',
              wu_liao_code: '自动生成',
              counts: '100',
              counts_unit: '1',
              unit: '件',
              process_list: [],
              children: []
            }
          ]
        },
        {
          type: 'bom表',
          craft: '椅子',
          texture: '316',
          sales_specification: 'bom1',
          wu_liao_code: '自动生成',
          counts: '100',
          counts_unit: '1',
          unit: '张',
          process_list: [],
          children: [
            {
              type: '原料',
              craft: '装饰管3',
              texture: '316',
              sales_specification: '10*1*6000',
              wu_liao_code: '自动生成',
              counts: '17',
              counts_unit: '1',
              unit: '支',
              process_list: [],
              children: [
                {
                  type: '零件',
                  craft: '装饰管3',
                  texture: '316',
                  sales_specification: '10*1*480',
                  wu_liao_code: '自动生成',
                  counts: '200',
                  counts_unit: '2',
                  unit: '件',
                  process_list: [],
                  children: []
                }
              ]
            },
            {
              type: '原料',
              craft: '装饰管4',
              texture: '316',
              sales_specification: '10*1*6000',
              wu_liao_code: '自动生成',
              counts: '40',
              counts_unit: '1',
              unit: '支',
              process_list: [],
              children: [
                {
                  type: '零件',
                  craft: '装饰管4',
                  texture: '316',
                  sales_specification: '10*1*580',
                  wu_liao_code: '自动生成',
                  counts: '400',
                  counts_unit: '2',
                  unit: '件',
                  process_list: [],
                  children: []
                }
              ]
            },
            {
              type: 'bom表',
              craft: '椅子背',
              texture: '316',
              sales_specification: 'bom2',
              wu_liao_code: '自动生成',
              counts: '400',
              counts_unit: '4',
              unit: '个',
              process_list: [],
              children: [
                {
                  type: '原料',
                  craft: '装饰管5',
                  texture: '316',
                  sales_specification: '10*1*6000',
                  wu_liao_code: '自动生成',
                  counts: '229',
                  counts_unit: '4',
                  unit: '支',
                  process_list: [],
                  children: [
                    {
                      type: '零件',
                      craft: '装饰管5',
                      texture: '316',
                      sales_specification: '10*1*780',
                      wu_liao_code: '自动生成',
                      counts: '1600',
                      counts_unit: '4',
                      unit: '件',
                      process_list: [],
                      children: []
                    }
                  ]
                },
                {
                  type: '原料',
                  craft: '装饰管6',
                  texture: '316',
                  sales_specification: '10*1*6000',
                  wu_liao_code: '自动生成',
                  counts: '267',
                  counts_unit: '4',
                  unit: '支',
                  process_list: [],
                  children: [
                    {
                      type: '零件',
                      craft: '装饰管6',
                      texture: '316',
                      sales_specification: '10*1*880',
                      wu_liao_code: '自动生成',
                      counts: '1600',
                      counts_unit: '4',
                      unit: '件',
                      process_list: [],
                      children: []
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  display: flex;
  flex-direction: column;
  .group-st {
    flex: 1;
    display: flex;
    // align-items: center;
    padding: 10px 0;
    & ~ .group-st {
      border-top: 1px solid #ddd;
    }
    .group-left {
      display: flex;
    }
    .group-right {
      flex: 1;
    }
  }
}
</style>
