<template>
  <div class="page">找不到页面</div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.page {
}
</style>
