<template>
  <el-dialog title="审核出库" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <div>上传装车照片</div>
      <e-upload-image
        v-if="selfData.zhuang_che_photo_list"
        size="120px"
        class="m-t-10"
        :list.sync="selfData.zhuang_che_photo_list"
        isDelete
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    activeId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        id: '',
        zhuang_che_photo_list: [],
        chu_ku_status: '已审核'
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.selfData.id = this.activeId
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      let params = {
        ...this.selfData
      }
      this.$api({
        method: 'post',
        url: '/admin/gcvip/chuKu/changeStatus',
        data: params
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
