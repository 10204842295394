import { render, staticRenderFns } from "./popup-click-dispatch.vue?vue&type=template&id=019eb28a&scoped=true"
import script from "./popup-click-dispatch.vue?vue&type=script&lang=js"
export * from "./popup-click-dispatch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "019eb28a",
  null
  
)

export default component.exports