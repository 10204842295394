<template>
  <div class="page-main">
    <ProductionTabs />

    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" :loading="loading">
      <template #buttons>
        <el-button type="primary" size="mini" @click="itemCreateTask">创建任务</el-button>
        <el-button type="primary" size="mini" @click="itemFinishProcess">完结工单</el-button>
        <el-button v-if="false" type="primary" size="mini" @click="itemClickDispatch">
          一键派工
        </el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 187"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_need_wu_liao_code="{ row }">
        <el-link type="primary" @click="openPopupParentCode(row)">
          {{ row.need_wu_liao_code.join(',') }}
        </el-link>
      </template>
      <template #default_process_order_name="{ row }">
        <el-link type="primary" @click="openPopupProcessStep(row)">
          {{ row.process_order_name }}
        </el-link>
      </template>
      <template #default_ji_hua_kai_gong_date="{ row }">
        {{ row.ji_hua_kai_gong_date.slice(0, 10) }}
      </template>
      <template #default_ji_hua_wan_cheng_date="{ row }">
        {{ row.ji_hua_wan_cheng_date.slice(0, 10) }}
      </template>
      <template #default_test_standard="{ row }">
        <el-link type="primary" @click="openPopupTest(row)"> 查看 </el-link>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 创建任务 -->
    <PopupCreateTask
      v-if="isShowPopupCreateTask"
      :show.sync="isShowPopupCreateTask"
      :data="activeObj"
      @success="searchListData"
    />

    <!-- 一键派工 -->
    <PopupClickDispatch
      v-if="isShowPopupClickDispatch"
      :show.sync="isShowPopupClickDispatch"
      :caiGouBillId="activeObj.cai_gou_bill_id"
      @success="searchListData"
    />

      <!-- 完结工单 :caiGouBillId="activeObj.cai_gou_bill_id" -->
    <PopupFinishProcess
      v-if="isShowPopupFinishProcess"
      :show.sync="isShowPopupFinishProcess"
      :data="activeObj"
      @success="searchListData"
    />

    <!-- 所需物料 -->
    <b-popup-parent-code
      v-if="isShowPopupParentCode"
      :show.sync="isShowPopupParentCode"
      :data="activeObj"
    />

    <!-- 加工工序 -->
    <b-popup-process-step
      v-if="isShowPopupProcessStep"
      :show.sync="isShowPopupProcessStep"
      :data="activeObj"
    />

    <!-- 检测标准 -->
    <b-popup-test v-if="isShowPopupTest" :show.sync="isShowPopupTest" :data="activeObj" />
  </div>
</template>

<script>
import ProductionTabs from '../common/production-tabs'
import PopupCreateTask from './popup-create-task'
import PopupClickDispatch from './popup-click-dispatch'
import PopupFinishProcess from './popup-finish-process'
export default {
  components: {
    ProductionTabs,
    PopupCreateTask,
    PopupClickDispatch,
    PopupFinishProcess
  },
  data() {
    return {
      loading: true,

      // filter
      filterData: {},
      sortData: {
        cai_gou_stock_id: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/caigou/searchHeard',
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          width: 40
        },
        {
          title: 'ID',
          field: 'id',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code',
          slots: { header: 'header_autocomplete', default: 'default_need_wu_liao_code' }
        },
        {
          title: '工序',
          slots: { default: 'default_process_order_name' }
        },
        {
          title: '工艺',
          field: 'process_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '材质',
          field: 'texture',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '规格',
          width: 120,
          field: 'specification',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单位',
          field: 'unit',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '计划生产数量',
          field: 'cai_gou_stock_counts',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '实际生产数量',
          field: 'shi_ji_sheng_chan_count',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '单价(元)',
          field: 'cai_gou_stock_price',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '金额(元)',
          field: 'cai_gou_stock_amount',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '交货日期',
          field: 'cai_gou_jiao_huo_ri_qi',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '计划开工日期',
          field: 'ji_hua_kai_gong_date',
          slots: { header: 'header_autocomplete', default: 'default_ji_hua_kai_gong_date' }
        },
        {
          title: '计划完工日期',
          field: 'ji_hua_wan_cheng_date',
          slots: { header: 'header_autocomplete', default: 'default_ji_hua_wan_cheng_date' }
        },
        {
          title: '工单负责人',
          field: 'cao_zuo_yuan',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '检测标准',
          field: 'test_standard',
          slots: { header: 'header_autocomplete', default: 'default_test_standard' }
        }
      ],
      pageOptions: {
        ...this.$constant.page
      },
      totalObj: {
        sum_cai_gou_stock_counts: '',
        sum_shi_ji_sheng_chan_count: '',
        sum_cai_gou_amount: ''
      },

      isShowPopupCreateTask: false, // 是否显示创建任务
      isShowPopupClickDispatch: false, // 是否显示一键派工
      isShowPopupFinishProcess: false, // 是否显示完结工单
      isShowPopupParentCode: false, // 是否显示所需物料弹窗
      isShowPopupProcessStep: false, // 是否显示加工工序弹窗
      isShowPopupTest: false, // 是否显示产品工艺管控卡弹窗
      activeObj: ''
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/gongDan/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.totalObj.sum_cai_gou_stock_counts = res.data.sum_cai_gou_stock_counts
            this.totalObj.sum_shi_ji_sheng_chan_count = res.data.sum_shi_ji_sheng_chan_count
            this.totalObj.sum_cai_gou_amount = res.data.sum_cai_gou_amount
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (column.field === 'shi_ji_sheng_chan_count')
            return this.totalObj.sum_shi_ji_sheng_chan_count
          if (column.field === 'cai_gou_stock_amount') return this.totalObj.sum_cai_gou_amount
          if (column.field === 'cai_gou_stock_counts') return this.totalObj.sum_cai_gou_stock_counts
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row, true) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-详情
    itemDetail(row) {
      window.open(`/sale/order/detail?id=${row.bill_id_b}`, '_blank')
    },
    // 项-创建任务
    itemCreateTask() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemCreateTaskAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-创建任务
    itemCreateTaskAction(row) {
      this.activeObj = row
      this.isShowPopupCreateTask = true
    },
    // 项-一键派工
    itemClickDispatch(row) {
      this.activeObj = row
      this.isShowPopupClickDispatch = true
    },
    // 项-完结工单
    itemFinishProcess() {
      let row = this.getRadioRecord()
      if (row) {
        this.itemFinishProcessAction(row)
      } else {
        this.itemChoosePrompt()
      }
    },
    // 项-创建任务
    itemFinishProcessAction(row) {
      this.activeObj = row
      this.isShowPopupFinishProcess = true
    },
    // 所需物料
    openPopupParentCode(row) {
      this.activeObj = row
      this.isShowPopupParentCode = true
    },
    // 加工工序
    openPopupProcessStep(row) {
      this.activeObj = row
      this.isShowPopupProcessStep = true
    },
    // 检测标准
    openPopupTest(row) {
      this.activeObj = row
      this.isShowPopupTest = true
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
