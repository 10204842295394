<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" import export print custom>
      <template #buttons>
        <el-button type="success" size="mini" @click="itemAdd">新增</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      show-overflow
      highlight-current-row
      :max-height="this.$util.getViewHeight() - 150"
      :print-config="{}"
      :scroll-y="{ enabled: false }"
      :data="tableDataMain"
      :columns="tableColumn"
      :mouse-config="{ selected: false }"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_operate="{ row }">
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 增改参数弹窗 -->
    <PopupDictDetail
      v-if="isShowPopupDictDetail"
      :show.sync="isShowPopupDictDetail"
      :data.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupDictDetail from './popup-dict-detail'
export default {
  components: { PopupDictDetail },
  data() {
    return {
      // 图片end
      gcvipDict: {},
      dictForm: {
        id: '',
        p_id: '',
        name: '',
        remark: '',
        shortcut_key: ''
      },

      headerFilterUrl: '/admin/gcvip/dict/searchHeard',
      tableColumn: [
        {
          field: 'id',
          width: 120,
          title: '序号',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'name',
          title: '名称',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'shortcut_key',
          title: '快捷键',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'remark',
          width: 180,
          title: '说明',
          slots: { header: 'header_autocomplete' }
        },
        { title: '操作', width: 120, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      },

      isShowPopupDictDetail: false,
      itemObj: {},

      filterData: {},
      sortData: { id: 'asc' }
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    },
    itemDefault() {
      return {
        id: '',
        p_id: this.$route.query.id,
        name: '',
        remark: '',
        shortcut_key: ''
      }
    }
  },
  created() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联
      this.$refs.refTable.connect(this.$refs.refToolbar)
      this.initItemObj()
      this.searchListData()
    })
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dict/searchDictDetail',
        data: {
          id: this.id,
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      }).then((res) => {
        if (res.status === 200) {
          this.tableDataMain = res.data.page.list
          this.pageOptions.pageSize = res.data.page.pageSize
          this.pageOptions.total = res.data.page.totalRow
          this.pageOptions.currentPage = res.data.page.pageNumber
          this.gcvipDict = res.data.gcvipDict
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupDictDetail = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupDictDetail = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$confirm(`您确定要删除该数据?`, '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/dict/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
