<template>
  <el-dialog title="销售出库" width="1400px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.formData"
      />
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn"
        :data="selfDetail.list"
      >
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush">提交出库</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    billId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        formData: {
          chu_ku_code: '', // 采购申请编号
          driver_car_code: '', // 预约车牌号
          chu_ku_yu_yue_time: '', // 预约出库日期
          cun_fang_wei_zhi_list: [], // 存放位置
          chu_ku_remark: '' // 出库备注
        },
        list: []
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      formColumn: [
        {
          type: 'text',
          title: '出库单号',
          field: 'chu_ku_code',
          placeholder: '自动生成',
          disabled: true
        },
        {
          type: 'text',
          title: '预约车牌号',
          field: 'driver_car_code'
        },
        {
          type: 'date',
          title: '预约出库日期',
          field: 'chu_ku_yu_yue_time',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'upload',
          title: '存放位置',
          field: 'cun_fang_wei_zhi_list',
          size: '28px',
          isDelete: true
        },
        {
          type: 'text',
          title: '出库备注',
          field: 'chu_ku_remark'
        }
      ],
      tableColumn: [
        {
          title: '序号',
          type: 'seq',
          width: 40
        },
        {
          title: '物料号',
          field: 'wu_liao_code'
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code'
        },
        {
          title: '品名',
          field: 'craft'
        },
        {
          title: '材质',
          field: 'texture'
        },
        {
          title: '规格',
          field: 'specification'
        },
        {
          title: '出库数量',
          field: 'chu_ku_stock_counts'
        },
        {
          title: '单位',
          field: 'unit'
        }
      ]
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/chuKu/buildChuKuBill',
        params: {
          song_huo_bill_id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfDetail = this.$util.deepCopy(res.data)
          let dateStr = this.selfDetail.formData.chu_ku_yu_yue_time
          if (!dateStr) {
            this.selfDetail.formData.chu_ku_yu_yue_time = this.$XEUtils.toDateString(
              this.$XEUtils.now(),
              'yyyy-MM-dd'
            )
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/chuKu/saveOrUpdate',
        data: this.selfDetail
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
