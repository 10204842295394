<template>
  <div>
    <el-dialog title="工序" :visible.sync="selfShow" :close-on-click-modal="false">
      <div>
        <vxe-grid
          ref="refTable"
          :size="tableConfig.size"
          :border="tableConfig.border"
          :min-height="tableConfig.minHeight"
          :edit-config="tableConfig.editConfig"
          :row-config="tableConfig.rowConfig"
          :mouse-config="tableConfig.mouseConfig"
          :keyboard-config="tableConfig.keyboardConfig"
          :columns="tableColumn"
          :data="selfData"
        >
          <template #default_test_standard="{ row }">
            <el-link type="primary" @click="openPopupTest(row)"> 查看 </el-link>
          </template>
        </vxe-grid>
      </div>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 检测标准 -->
    <b-popup-test v-if="isShowPopupTest" :show.sync="isShowPopupTest" :data="activeObj" />
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: [],
      tableColumn: [
        {
          title: '工艺流程',
          field: 'process_order_name'
        },
        {
          title: '加工工艺',
          field: 'process_name'
        },
        {
          title: '检测标准',
          slots: { default: 'default_test_standard' }
        },
        {
          title: '加工单价(元)',
          field: 'process_price'
        },
        {
          title: '加工数量',
          field: 'process_count'
        },
        {
          title: '加工金额(元)',
          field: 'process_amount'
        }
      ],
      isShowPopupTest: false,
      activeObj: {}
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getListData()
  },
  methods: {
    // 获取列表数据
    getListData() {
      let params = {
        id_stock: this.data.id_stock
      }
      this.$api({
        method: 'get',
        url: '/admin/gcvip/factory/getGongXun',
        params
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfData = res.data.list
        }
      })
    },
    // 检测标准
    openPopupTest(row) {
      this.activeObj = row
      this.isShowPopupTest = true
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="less" scoped></style>
