<template>
  <div class="page-main">
    <div class="role-name">
      <span>角色名称：</span>
      <span class="c-green">{{ role.name }}</span>
    </div>
    <div v-for="(value, key) in permissionMap" :key="key">
      <div class="table-title m-b-10">
        <vxe-switch
          class="m-r-10"
          v-model="permissionMap[key][0].assigned"
          @change="allSwitch(key)"
        />
        <strong>{{ key }}</strong>
      </div>

      <!-- 表格 -->
      <vxe-grid
        class="m-b-10"
        size="mini"
        border
        highlight-hover-row
        :min-height="32"
        :show-header="false"
        :mouse-config="{ selected: false }"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
        :data="permissionMap[key]"
        :columns="tableColumn"
        :keyboard-config="{
          enterToTab: false,
          isArrow: true,
          isDel: false,
          isEnter: false,
          isTab: true,
          isEdit: true
        }"
      >
        <template #default_operate="{ row }">
          <template>
            <vxe-switch v-model="row.assigned" @change="changeRoleEvent({ row })"></vxe-switch>
            <vxe-button
              class="m-l-5"
              icon="el-icon-delete"
              title="删除"
              circle
              @click="itemDelete(row)"
            />
          </template>
        </template>
      </vxe-grid>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assignedList: [],
      role_id: '',
      role: {},
      permissionMap: {},
      showEdit: false,
      tableColumn: [
        { field: 'id', title: 'ID', width: 60 },
        { field: 'action_key', width: 360, title: '名称' },
        {
          field: 'remark',
          width: 360,
          title: '备注',
          editRender: { name: 'input', events: { blur: this.remarkEvent } }
        },
        { title: '操作', width: 160, slots: { default: 'default_operate' } }
      ]
    }
  },
  methods: {
    allSwitch(key) {
      const b = this.permissionMap[key][0].assigned
      this.assignedList = []
      this.permissionMap[key].forEach((row) => {
        row.assigned = b
        this.assignedList.push(row.id)
      })
      this.$api({
        method: 'post',
        url: '/admin/role/assignPermissionSwitchListVue',
        data: {
          role_id: this.role_id,
          assignedList: this.assignedList,
          checked: b
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    changeRoleEvent({ row }) {
      row.assigned = row.assigned == false ? true : false
      this.$api({
        method: 'post',
        url: '/admin/role/assignPermissionSwitchVue',
        params: {
          role_id: this.role_id,
          permission_id: row.id,
          checked: !row.assigned
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success('编辑成功')
          row.assigned = !row.assigned
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    remarkEvent(value, $event) {
      this.$api({
        method: 'post',
        url: '/admin/permission/updateVue',
        params: {
          id: value.row.id,
          remark: $event.target.value
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success('编辑成功')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    editRowEvent(row) {
      this.showEdit = true
      this.roleForm.name = ''
      this.$api({
        method: 'post',
        url: '/admin/role/editVue',
        params: {
          id: row.id
        }
      }).then((res) => {
        if (res.status === 200) {
          this.roleForm.name = res.data.role.name
          this.roleForm.remark = res.data.role.remark
          this.roleForm.id = res.data.role.id
        }
      })
    },
    assignPermissionsVue() {
      this.$api({
        method: 'post',
        url: '/admin/role/assignPermissionsVue',
        params: {
          id: this.role_id
        }
      }).then((res) => {
        if (res.status === 200) {
          this.role = res.data.role
          this.permissionMap = res.data.permissionMap
        }
      })
    },
    itemDelete(row) {
      this.$confirm('您确定要删除该数据?', '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/permission/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.assignPermissionsVue()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  },
  created() {
    this.role_id = this.$route.query.id
    this.assignPermissionsVue()
  }
}
</script>

<style lang="less" scoped>
.page-main {
  .role-name {
    padding: 10px 5px;
    background: @colorGrayF;
    margin-bottom: 10px;
  }
  .table-title {
    padding: 0 8px;
    background-color: #fff6f7;
    border-left: 4px solid #fe6c6f;
  }
}
</style>
