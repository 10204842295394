<template>
  <div class="box-table-sale">
    <vxe-grid
      ref="refTableSale"
      class="table-sale"
      :size="tableConfig.size"
      :border="tableConfig.border"
      :min-height="tableConfig.minHeight"
      :header-cell-class-name="cellClassName"
      :cell-class-name="cellClassName"
      :toolbar-config="tableConfig.toolbarConfig"
      :edit-config="tableConfig.editConfig"
      :row-config="tableConfig.rowConfig"
      :mouse-config="tableConfig.mouseConfig"
      :keyboard-config="tableConfig.keyboardConfig"
      :edit-rules="validRules"
      :columns="tableColumn"
      :data="selfData"
      @edit-disabled="cellEditDisabled"
      @edit-activated="cellEditActivated"
      @edit-closed="cellEditClosed"
    >
      <!-- 表格按钮位置 -->
      <template #buttons>
        <div v-if="tableTitleArr.length > 1" class="table-title">
          <span v-for="(item, index) in tableTitleArr" :key="index">
            <span :class="item.isActive ? 'c-green' : ''">{{ item.name }}</span>
            <span v-show="!item.isActive && item.name">-</span>
          </span>
        </div>
        <div v-else class="table-title">
          <span class="c-green">销售清单</span>
        </div>
      </template>

      <!-- 表格工具条位置 -->
      <template #tools>
        <div style="margin-right: -10px">
          <el-button type="success" size="mini" :disabled="disabled" plain @click="rowAdd">
            增行
          </el-button>
          <el-button type="danger" size="mini" :disabled="disabled" plain @click="rowDelete">
            删行
          </el-button>
        </div>
      </template>

      <!-- 品类 -->
      <template #edit_category="{ row }">
        <el-select
          v-model="row.category"
          size="mini"
          :disabled="!!row.tableBomData.length"
          filterable
          @change="changeCategory(row)"
        >
          <el-option
            v-for="(item, index) in categoryList"
            :key="index"
            :label="item.key"
            :value="item.val"
          />
        </el-select>
      </template>

      <!-- 品名 -->
      <template #edit_craft="{ row }">
        <el-input v-model="row.craft" size="mini" clearable />
        <!-- 暂未使用下拉，用输入框 -->
        <!-- <el-select v-model="row.craft" size="mini" filterable allow-create default-first-option>
          <el-option
            v-for="(item, index) in craftList"
            :key="index"
            :label="item.key"
            :value="item.val"
          />
        </el-select> -->
      </template>

      <!-- 材质 -->
      <template #edit_texture="{ row }">
        <el-select v-model="row.texture" size="mini" filterable allow-create default-first-option>
          <el-option
            v-for="(item, index) in textureList"
            :key="index"
            :label="item.key"
            :value="item.key"
          />
        </el-select>
      </template>

      <!-- 规格 -->
      <template #edit_sales_specification="{ row }">
        <el-input v-model="row.sales_specification" size="mini" clearable />
      </template>

      <!-- 每套数量 -->
      <template #edit_counts_unit="{ row }">
        <el-input
          v-model="row.counts_unit"
          type="number"
          size="mini"
          clearable
          @input="changeCounts(row)"
        />
      </template>

      <!-- 数量 -->
      <template #edit_counts="{ row }">
        <el-input
          v-model="row.counts"
          type="number"
          size="mini"
          clearable
          @input="changeCounts(row)"
        />
      </template>

      <!-- 单位 -->
      <template #edit_unit="{ row }">
        <el-select v-model="row.unit" size="mini" filterable allow-create default-first-option>
          <el-option
            v-for="(item, index) in unitList"
            :key="index"
            :label="item.key"
            :value="item.val"
          />
        </el-select>
      </template>

      <!-- 每件材料成本 -->
      <template #default_cost_price_unit="{ row, rowIndex }">
        <div class="flex flex-y-center flex-x-between">
          <span>{{ row.cost_price_unit }}</span>
          <el-link
            :disabled="!!row.tableBomData.length"
            type="primary"
            @click="openPopupMaterial(rowIndex)"
          >
            {{ row.tableBomData.length ? 'bom表' : '材料' }}
          </el-link>
        </div>
      </template>

      <!-- 加工组装 -->
      <template #default_process_price_unit="{ row, rowIndex }">
        <div class="flex flex-y-center flex-x-between">
          <span>{{ row.process_price_unit }}</span>
          <el-link type="primary" @click="openPopupProcess(rowIndex)">
            {{ row.tableBomData.length ? '组装' : '加工' }}
          </el-link>
        </div>
      </template>

      <!-- 销售单价 -->
      <template #edit_sales_price="{ row }">
        <el-input
          v-model="row.sales_price"
          type="number"
          size="mini"
          clearable
          @input="changeSalesPrice(row)"
        />
      </template>

      <!-- 销售金额 -->
      <template #default_sales_amount="{ row }">
        {{ !parent || Number(row.sales_price) ? row.sales_amount : '' }}
      </template>

      <!-- 每件利润 -->
      <template #default_profit_row_unit="{ row }">
        {{ !parent || Number(row.sales_price) ? row.profit_row_unit : '' }}
      </template>

      <!-- 合计利润 -->
      <template #default_profit_row="{ row }">
        {{ !parent || Number(row.sales_price) ? row.profit_row : '' }}
      </template>

      <!-- 利润率 -->
      <template #default_profit_rate="{ row }">
        {{ !parent || Number(row.sales_price) ? row.profit_rate : '' }}
      </template>

      <!-- 产品图片 -->
      <template #default_photo_list="{ row }">
        <e-upload-image
          v-if="row.photo_list"
          :list.sync="row.photo_list"
          size="30px"
          :limit="2"
          :disabled="disabled"
          isDelete
        />
      </template>

      <template #default_do="{ row, rowIndex }">
        <el-link
          v-if="row.tableBomData.length"
          type="danger"
          :disabled="disabled"
          @click="bomDelete(row, rowIndex)"
        >
          删除bom表
        </el-link>
        <el-link v-else type="success" :disabled="disabled" @click="bomAdd(row, rowIndex)">
          创建bom表
        </el-link>
      </template>
    </vxe-grid>

    <!-- bom表 递归 -->
    <template v-for="(item, index) in selfData">
      <TableSale
        v-if="item.tableBomData.length"
        :key="index"
        :tableTitle="`${tableTitle}-${item.craft}`"
        :parent="item"
        :data.sync="item.tableBomData"
        :disabled="disabled"
        :statusName="statusName"
        :categoryList="categoryList"
        :craftList="craftList"
        :textureList="textureList"
        :unitList="unitList"
      />
    </template>

    <!-- 材料-默认 -->
    <PopupMaterialDefault
      v-if="isShowPopupMaterialDefault"
      :show.sync="isShowPopupMaterialDefault"
      :data.sync="selfData[activeIndex]"
      :disabled="disabled"
    />
    <!-- 材料-其他 -->
    <PopupMaterialOther
      v-if="isShowPopupMaterialOther"
      :show.sync="isShowPopupMaterialOther"
      :data.sync="selfData[activeIndex]"
      :disabled="disabled"
    />
    <!-- 加工组装 -->
    <PopupProcess
      v-if="isShowPopupProcess"
      :show.sync="isShowPopupProcess"
      :data.sync="selfData[activeIndex]"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import PopupMaterialDefault from './popup-material-default'
import PopupMaterialOther from './popup-material-other'
import PopupProcess from './popup-process'
import math from './math'
export default {
  name: 'TableSale',
  components: {
    PopupMaterialDefault,
    PopupMaterialOther,
    PopupProcess
  },
  props: {
    tableTitle: {
      type: String,
      required: true
    },
    parent: {
      type: Object,
      default: () => {
        return null
      }
    },
    data: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    statusName: {
      type: String,
      default: ''
    },
    categoryList: {
      type: Array,
      required: true
    },
    craftList: {
      type: Array,
      required: true
    },
    textureList: {
      type: Array,
      required: true
    },
    unitList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rowIndex: null,
      columnIndex: null,
      validRules: {
        category: [{ required: true }],
        craft: [{ required: true }]
      },
      tableColumn: [
        {
          title: '序号',
          type: 'seq',
          width: 38
        },
        {
          title: '物料号',
          width: 90,
          field: 'wu_liao_code',
          formatter: ({ cellValue, row }) => {
            let result = ''
            if (cellValue) {
              result = cellValue
            } else {
              if (!row.bill_id) result = this.$tool.autoGenerate('')
            }
            return result
          }
        },
        {
          title: '所需物料号',
          field: 'need_wu_liao_code',
          width: 80,
          formatter: ({ cellValue, row }) => {
            let result = ''
            if (cellValue.length) {
              result = cellValue.join(',')
            } else {
              if (!row.bill_id) result = this.$tool.autoGenerate('')
            }
            return result
          }
        },
        {
          title: '商品类目',
          field: 'category',
          width: 90,
          slots: { edit: 'edit_category' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '品名',
          field: 'craft',
          width: 100,
          slots: { edit: 'edit_craft' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '材质',
          field: 'texture',
          width: 80,
          slots: { edit: 'edit_texture' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '规格',
          field: 'sales_specification',
          width: 120,
          slots: { edit: 'edit_sales_specification' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '每套数量',
          field: 'counts_unit',
          width: 80,
          slots: { edit: 'edit_counts_unit' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '数量',
          field: 'counts',
          width: 80,
          slots: { edit: 'edit_counts' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '单位',
          field: 'unit',
          width: 80,
          slots: { edit: 'edit_unit' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '每件材料成本(元)',
          width: 106,
          slots: { default: 'default_cost_price_unit' }
        },
        {
          title: '每件加工组装(元)',
          width: 106,
          slots: { default: 'default_process_price_unit' }
        },
        {
          title: '销售单价(元)',
          field: 'sales_price',
          slots: { edit: 'edit_sales_price' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '销售金额(元)',
          slots: { default: 'default_sales_amount' }
        },
        {
          title: '每件利润(元)',
          slots: { default: 'default_profit_row_unit' }
        },
        {
          title: '合计利润(元)',
          slots: { default: 'default_profit_row' }
        },
        {
          title: '利润率(%)',
          slots: { default: 'default_profit_rate' }
        },
        {
          title: '产品图片',
          width: 75,
          slots: { default: 'default_photo_list' }
        },
        {
          title: 'bom表操作',
          width: 94,
          slots: { default: 'default_do' }
        }
      ],
      selfData: [],
      isShowPopupMaterialDefault: false, // 是否显示材料计算弹窗-默认
      isShowPopupMaterialOther: false, // 是否显示材料计算弹窗-其他
      isShowPopupProcess: false, // 是否显示加工计算弹窗
      activeIndex: null // 当前行索引
    }
  },
  provide() {
    return {
      getParentTableObj: () => {
        return this.selfData[this.activeIndex]
      }
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        toolbarConfig: {
          enabled: true,
          slots: {
            buttons: 'buttons',
            tools: 'tools'
          }
        },
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    },
    // 表格标题
    tableTitleArr() {
      let arr = this.tableTitle.split('-')
      let result = arr.map((item, index) => {
        return {
          name: item,
          isActive: index === arr.length - 1
        }
      })
      return result
    }
  },
  watch: {
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
          if (this.rowIndex !== null && this.columnIndex !== null) {
            this.$refs.refTableSale.setEditCell(
              this.selfData[this.rowIndex],
              this.tableColumn[this.columnIndex].field
            )
          }
        }
      },
      deep: true,
      immediate: true
    },
    selfData: {
      handler(val) {
        if (JSON.stringify(this.data) !== JSON.stringify(val)) {
          this.$emit('update:data', this.$util.deepCopy(val))
        }
      },
      deep: true
    }
  },
  created() {
    this.initTableColumn()
  },
  methods: {
    // 初始化表格列
    initTableColumn() {
      let unitIndex, countsUnitIndex, countsIndex
      this.tableColumn.forEach((item, index) => {
        if (item.title === '规格') unitIndex = index
        if (item.title === '每套数量') countsUnitIndex = index
        if (item.title === '数量') countsIndex = index
      })
      if (this.parent) {
        // bom表的数量不可编辑
        delete this.tableColumn[countsIndex].editRender
        delete this.tableColumn[countsIndex].slots
      } else {
        // 销售清单的每套数量隐藏
        this.tableColumn[unitIndex].width = 200
        this.tableColumn.splice(countsUnitIndex, 1)
      }
    },
    // 表格单元格样式设置
    cellClassName({ column }) {
      if (column.title === 'bom表操作') return 'bg-green'
      if (column.slots && column.slots.edit) return 'edit'

      return null
    },
    // 单元格-编辑禁用
    cellEditDisabled() {
      this.$message.error(`当前状态为${this.statusName}，不可编辑`)
    },
    // 单元格-编辑选中
    cellEditActivated(data) {
      this.rowIndex = data.rowIndex
      this.columnIndex = data.columnIndex
    },
    // 单元格-编辑关闭
    cellEditClosed() {
      this.rowIndex = null
      this.columnIndex = null
    },
    // 获取-当前选中记录
    getCurrentRecord() {
      return this.$refs.refTableSale.getCurrentRecord()
    },
    // 获取-当前选中记录索引
    getRowIndex(row) {
      return this.$refs.refTableSale.getRowIndex(row)
    },
    // 行-增行
    rowAdd() {
      let result = this.$util.deepCopy(this.$constant.tableSaleObj)
      this.selfData.push(result)
      this.$refs.refTableSale.clearCurrentRow()
    },
    // 行-删行
    rowDelete() {
      if (!this.parent) {
        if (this.selfData.length <= 1) {
          this.$message.error('销售清单至少保留一行')
          return
        }
      } else {
        if (this.selfData.length <= 2) {
          this.$message.error('bom表清单至少保留两行')
          return
        }
      }
      let row = this.getCurrentRecord()
      if (row) {
        let index = this.getRowIndex(row)
        this.selfData.splice(index, 1)
        this.$refs.refTableSale.clearCurrentRow()
      } else {
        this.$tool.tableChoosePrompt()
      }
    },
    // bom-新增
    bomAdd(row) {
      if (row.category && row.craft) {
        row.category = '其他'
        let result = this.$util.deepCopy(this.$constant.tableSaleObj)
        row.tableBomData.push(result)
        row.tableBomData.push(result)
      } else {
        this.$message.error('创建bom表前，请先填写商品类目和品名')
      }
    },
    // bom-删除
    bomDelete(row) {
      row.tableBomData = []
    },
    // 材料弹窗
    openPopupMaterial(index) {
      this.activeIndex = index
      if (this.selfData[index].category === '其他') {
        this.isShowPopupMaterialOther = true
      } else {
        this.isShowPopupMaterialDefault = true
      }
    },
    // 加工组装弹窗
    openPopupProcess(index) {
      this.activeIndex = index
      this.isShowPopupProcess = true
    },
    // 更新-品类
    changeCategory(row) {
      if (row.category !== '其他') {
        if (row.material_length === '') row.material_length = '6000'
        if (row.today_base_price === '') row.today_base_price = '14'
      }
    },
    // 更新-数量
    changeCounts(row) {
      math.rowCounts(row, this.parent) // 计算-数量
      // 是否有bom表
      if (row.tableBomData.length) {
        row.tableBomData.forEach((item) => {
          math.rowCounts(item, row) // 计算-数量
          if (item.tableBomData.length) this.changeCounts(item)
        })
      }
    },
    // 更新-销售单价
    changeSalesPrice(row) {
      math.totalRowSaleData(row) // 计算-汇总行销售
    }
  }
}
</script>

<style lang="less" scoped>
.box-table-sale {
  .table-title {
    font-size: 14px;
    color: #000;
    font-weight: bold;
  }
  .table-sale {
    margin: -1px;
  }
  /deep/ .vxe-grid--toolbar-wrapper {
    padding: 0 5px;
    border: 1px solid #e8eaec;
    border-bottom: none;
  }
  /deep/ .vxe-cell--valid-error-hint {
    display: none;
  }
  /deep/ .vxe-table--render-default .vxe-header--column.bg-green {
    background-color: #67c23a;
    color: #fff;
  }
  /deep/ .vxe-table--render-default.vxe-editable .vxe-body--column.bg-green {
    background-color: #f0f9eb;
  }
  /deep/ .vxe-toolbar {
    padding: 5px 0;
  }
}
</style>
