<template>
  <el-dialog
    :title="selfData.id ? '编辑账户' : '新增账户'"
    width="500px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfData"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {},
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'text',
          field: 'bank_short_name',
          title: '账户名称',
          required: true
        },
        {
          type: 'text',
          field: 'bank_code',
          title: '银行账号'
        },
        {
          type: 'text',
          field: 'bank_company',
          title: '开户名'
        },
        {
          type: 'text',
          field: 'bank_name',
          title: '开户行'
        },
        {
          type: 'text',
          field: 'bank_type',
          title: '账户备注'
        }
      ],
      rules: {
        bank_short_name: [{ required: true, message: '请输入账户名称' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.selfData = this.$util.deepCopy(this.data)
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/bank/saveOrUpdate',
            data: {
              data: this.selfData
            }
          })
            .then((res) => {
              if (res.data.state === 'ok') {
                this.popupCancel()
                this.$emit('success')
                this.$message.success('保存成功')
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              this.$message.error(error)
            })
        }
      })
    }
  }
}
</script>

<style></style>
